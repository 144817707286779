import { hotKeys } from '../constants/hotKeys';

export default {
  panelNames: [],
  toolNames: ['SignHereTagTemplateTool',
    'InitialHereTagTemplateTool'],
  hotKeys: [
    hotKeys.F,
    hotKeys.V,
    hotKeys.R,
    hotKeys.B,
    hotKeys.N,
    hotKeys.Q,
    hotKeys.W,
    hotKeys.E,
    hotKeys.T,
    hotKeys.S,
    hotKeys.C,
    hotKeys.A,
    hotKeys.Z,
    hotKeys.X,
    hotKeys.O,
    hotKeys.L,
    hotKeys.Y,
    hotKeys['5'],

  ],
};
