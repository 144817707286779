import * as R from 'ramda';
import _ from 'lodash';
import { INameSpec, IUserSpec } from '../types';

const upperFirst = _.upperFirst;

const getField = (prop: string) => R.propOr('', prop);



type ParseNameFn = (arg: INameSpec | IUserSpec) => string;



export interface IHasName {
  firstName: string,
  middleName?: string,
  lastName: string
}
const getName = (user: IHasName) => (nameField: string, def?: string | null): string => {
  const name = _.chain(user)
    .get(nameField, def)
    .toLower()
    .upperFirst()
    .value();
  return name;
}

export const parseName = (userObj: IHasName | { user: IHasName }, preferNs = false): string => {
  if (preferNs === true) {
    const getNameField = getName(userObj as IHasName);
    const firstName = getNameField('firstName');
    const middleName = getNameField('middleName', null);
    const lastName = getNameField('lastName');
    let fullName = `${firstName}${(middleName) ? ` ${middleName}` : ''} ${lastName}`;


    if (_.isEmpty(firstName) || _.isEmpty(lastName) || _.isNil(firstName) || _.isNil(lastName)) {
      const user = _.get(userObj, 'user', userObj);
      const getNameField = getName(user);
      const firstName = getNameField('firstName');
      const middleName = getNameField('middleName', null);
      const lastName = getNameField('lastName');

      fullName = `${firstName}${(middleName) ? ` ${middleName}` : ''} ${lastName}`
    }

    if (fullName === ' ') {
      return '';
    }
    return fullName;
  }
  const user = _.get(userObj, 'user', userObj);
  const getNameField = getName(user);
  const firstName = getNameField('firstName');
  const middleName = getNameField('middleName', null);
  const lastName = getNameField('lastName');

  const rtn = `${firstName}${(middleName) ? ` ${middleName}` : ''} ${lastName}`
  if (rtn === ' ') {
    return '';
  }
  return rtn;
}

type StringRtnStr = (str: string) => string;

export const toFullName: StringRtnStr = R.pipe(
  R.split(' '),
  R.map(upperFirst),
  R.join(' ')
);
