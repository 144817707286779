import React from 'react';
import styled from 'styled-components';

const NotaryRoomInfoStyles = styled.div`
.notary-room-info {
  margin-bottom: 1rem;
}
  #notary-room-header {
    display: flex !important;
    justify-content: space-around !important;
  }
  #notary-room-header{
    color: #347083;
  }
  #notary-room-header p{
    color: #757575;
    font-weight: 500;
    margin: 0 -10px;
  }
  #notary-room-header{
    margin-right: 3px;
  }
  #notary-room-header span{
    width: 100px !important;
    display: flex;
    justify-content: center;
    color: #347083;
    font-weight: 500;
    border-radius: 6px;
  }
  #state-of{
    background-color: #E0EEF0;
  }
  #com-id{
    margin-top: 6px;
    margin-left: 6px;
  }
  #com-id i{
    color: #347083;
  }
  #com-id p{
    display: flex;
  }
  #com-id span{
    margin-left: 6px;
  }
`;


export const NotaryRoomInfo = (props) => (
  <NotaryRoomInfoStyles>
    <div
      className='notary-room-info'
      style={props.style}
    >
      <div id='notary-room-header'>
        <i className='fas fa-map-marker-alt' />
        <p>
          Notary Public
        </p>
        <span id='state-of'>
          State of
          {' '}
          {props.commissionState}
        </span>
      </div>
      <div id='com-id'>
        <p>
          <i className='fas fa-university' />
          <span>
            {props.commissionId || ''}
          </span>
        </p>
      </div>
    </div>
  </NotaryRoomInfoStyles>
);

export default NotaryRoomInfo;
