import _ from 'lodash'

/**
 * @param xfdf {string} xfdf string
 * @returns xfdf with hidden annotations removed
 */
export function removeFromXFDF(xfdf: string, ids: string[] = []): string {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xfdf, "application/xml");


  if (ids.length > 0) {
    const toRemoveSelector = _.chain(ids)
      .map((id) => `[name="${id}"]`)
      .join(',')
      .value();

    xmlDoc.querySelectorAll(toRemoveSelector).forEach((a) => a.remove());

  }


  const annots = xmlDoc.querySelectorAll("annots > *");
  annots.forEach((annot) => {
    const isHidden = _.chain(annot.getAttribute('flags') || '')
      .split(',')
      .map(_.toLower)
      .find((el) => _.isEqual(el, 'hidden'))
      .thru((found) => !_.isNil(found))
      .value();

    // if annot is hidden then
    if (isHidden) {
      // remove from `<annots>`
      annot.remove();
    }
  });


  xmlDoc.querySelectorAll('freetext').forEach((ft) => {
    ft.removeAttribute('TextColor');
    ft.removeAttribute('FontSize');
    const crb = ft.querySelector('contents-richtext > body')
    if (crb) {
      crb.setAttribute('xmlns:xfa', 'http://www.xfa.org/schema/xfa-data/1.0/');
      crb.setAttribute('xfa:APIVersion', 'Acrobat:10.1.3');
      crb.setAttribute('xfa:spec', '2.0.2');
    }
  });
  // xmlDoc.querySelectorAll('freetext > apref-replace').forEach(a => a.remove());
  // Serialize xmlDoc back to a string (the updated XFDF)
  const serializer = new XMLSerializer();
  return serializer.serializeToString(xmlDoc);
}


