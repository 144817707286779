import createTemplateTool from './createTemplateTool';
import { dateFullDateIcon, monthIcon, dayIcon, yearIcon } from '../../constants/icons';

const createDateTemplateTool = createTemplateTool('Date', dateFullDateIcon);

const createMonthTemplateTool = createTemplateTool('Month', monthIcon);

const createDayTemplateTool = createTemplateTool('Day', dayIcon);

const createYearTemplateTool = createTemplateTool('Year', yearIcon);

export { createDateTemplateTool, createMonthTemplateTool, createDayTemplateTool, createYearTemplateTool };
