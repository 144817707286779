import * as R from 'ramda';
import _ from 'lodash';
import moment from 'moment';

// When working with massive amounts of data in memory, it makes sense to try to map it all once (or as infrequently as possible) instead of on every render.

// TODO: share constants between repos. This is based on the doc model info
const taggedDocStatuses = ['tagging-qa-ready', 'tagging-approved'];

const dateOrNAFormatter = (date, notSet) => {
  if (date instanceof Date || typeof date === 'string') {
    return moment(date).format('MM/DD/YYYY hh:mm A');
  }

  if (notSet) {
    return 'Not Set';
  }

  return 'N/A';
};

const getPrimarySigner = R.pipe(
  R.propOr([], 'notarySessionUsers'),
  R.find(R.pathEq(['type'], 'consumer')),
  R.prop('user')
);


const fullName = (user) => [user?.firstName, user?.lastName].filter((name) => !!name).join(' ');
const fmtNotarySession = R.applySpec({
  id: R.prop('id'),
  docs: R.prop('docs'),
  status: R.prop('status'),
  taggerNames: (ns) => ns.docs.map((doc) => doc?.tagger?.name),
  taggingProcess: (ns) => `${ns.docs.filter((doc) => taggedDocStatuses.includes(doc.status)).length} of ${ns.docs.length} tagged`,
  assignedTaggingTo: (ns) => ns.docs.find((doc) => !taggedDocStatuses.includes(doc.status) && doc.taggerId)?.taggerId,
  notaryName: (ns) => fullName(ns.notary?.user),
  completedAt: R.prop('completedAt'),
  completedAtString: (ns) => dateOrNAFormatter(ns.completedAt, true),
  updatedAtString: (ns) => dateOrNAFormatter(ns.updatedAt, true),
  updatedAt: R.prop('updatedAt'),
  sendingOrgId: R.prop('organizationId'),
  sendingOrgName: (ns) => ns.organization?.name,
  docTitle: R.pipe(R.propOr([], 'docs'), R.head, R.prop('title')),
  primarySignerName: R.pipe(getPrimarySigner, fullName),
  primarySigner: getPrimarySigner,
  signers: R.pipe(
    R.prop('notarySessionUsers'),
    R.filter(R.complement(R.propEq('type', 'consumer'))),
    R.map((nsu) => ({ ...nsu.user, type: nsu.type }))
  ),
  signerTypes: (ns) => ns.notarySessionUsers.map((nsu) => nsu.type),
  allSignerNames: (ns) => ns.notarySessionUsers.map((nsu) => fullName(nsu.user)),
  allSignerEmails: (ns) => ns.notarySessionUsers.map((nsu) => nsu.email),
  dateSent: R.prop('createdAt'),
  computedStatus: R.prop('computedStatus'),
  docIds: (ns) => ns.docs.map((doc) => doc.id),
});

const fmtType = (type) => {
  // Make abbreviations all caps
  if (/^r[oi]n$/i.test(type)) {
    return type.toUpperCase();
  }

  // The rest is just capitalized
  return _.capitalize(type);
};

const fmtNotarySessions = R.pipe(
  R.prop('notarySessions'),
  R.map(fmtNotarySession)
);

const fmtReqInfo = R.applySpec({
  requestGroupId: R.prop('requestGroupId'),
  paidBy: R.pipe(
    R.path(['requestGroup', 'orgPayment']),
    R.ifElse(R.equals(true), R.always('Organization'), R.always('Signer'))
  ),
  requestId: R.prop('id'),
  type: (req) => fmtType(req.notarySessions?.[0]?.notarizationType || req.type),
  dateSent: R.prop('createdAt'),
  dateSentString: (req) => dateOrNAFormatter(req.createdAt, true),
  dueDate: R.prop('dueDate'),
  dueDateOrNA: R.propOr('Not Set', 'dueDate'),
  dueDateString: (req) => dateOrNAFormatter(req.dueDate, true),
  assignedTo: R.prop('assigned'),
  assignedToOrg: R.prop('assignedOrg'),
  assignedToOrgName: (req) => req.assignedOrg?.name || 'eNotaryLog',
  assignedToName: R.pipe(R.prop('assigned'), fullName),
  expiresAt: R.prop('expiresAt'),
  expiresAtOrNA: R.propOr('Not Set', 'expiresAt'),
  expiresAtString: (req) => dateOrNAFormatter(req.expiresAt, true),
  sender: R.prop('sender'),
  senderName: R.pipe(R.prop('sender'), fullName),
  minSinceSent: (req) => moment().diff(moment(req.createdAt), 'minutes'),
});

export const toNsRequests = R.pipe(
  R.values,
  R.map(R.converge(R.map, [R.pipe(fmtReqInfo, R.mergeDeepLeft), fmtNotarySessions])),
  R.flatten
);
