import _ from 'lodash';
import { tagTypes } from '../constants/tagTypes';

import { NameTagKeys, AddressTagKeys } from '../types';


export const parseField = (type: string): string => {
  // const field = tagTypes.find
  if (type in tagTypes.addressTags) {
    const tagType = tagTypes.addressTags[type as AddressTagKeys];
    const field = tagType.field;

    return `No ${_.startCase(field)} Entered`;
  }

  if (type in tagTypes.nameTags) {
    const tagType = tagTypes.nameTags[type as NameTagKeys];
    const field = tagType.field;

    return `${_.startCase(field)}:________\nNo ${_.startCase(field)} Entered`;
  }

  return '';
};
