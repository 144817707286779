export const checkIfIframe = () => {
  const isIframe = new URLSearchParams(document.location.search).get('iframe');

  /*
    If the isIframe search param is not available, it means we are in the consumer notary room,
    since the consumer notary room is only used in V2, we can return a value of true.
  */
  if (isIframe === null) {
    return true;
  } else {
    /*
      If we get here, we are in the Notary Room. The Notary Room has a search param of
      isIframe=false if the session is in V1, and isIframe=true if the session is in V2
    */
    return isIframe === 'true';
  }
};
