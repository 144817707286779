// A custom hook that builds on useLocation to parse
// the query string for you.
import { useLocation } from "react-router-dom";


export function useQuery(key, defaultVal) {
  const searchParams = new URLSearchParams(useLocation().search);

  if (!key) {
    const query = {};
    searchParams.forEach((val, key) => {
      query[key] = val;
    });

    return query;
  }

  return searchParams.get(key) || defaultVal;
}

export const withQuery = (Component) => (props) => {
  const query = useQuery();

  return (
    <Component {...props} query={query} />
  )
}

export default useQuery;
