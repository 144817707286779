import * as R from 'ramda';
import _ from 'lodash';
import initRectAnnot from '../../initializers/initRectAnnot';
import registerAnnotationType from '../../initializers/registerAnnotationType';
import registerTool from '../../initializers/registerTool';
import defineToolClass from '../../initializers/defineToolClass';
import createTextField from '../../actions/createTextField';
import { parseName, defaultFontSize } from '@enotarylog/tag-utils'

const createTemplateTool = (name, buttonImage, hotKey, toolGroup) => R.pipeP(
  initRectAnnot(`${name}Template`, { label: name }),
  defineToolClass({
    className: `${name}TemplateTool`,
    annotClassName: `${name}TemplateRectAnnot`,
    baseClassName: 'GenericAnnotationCreateTool',
    // defaults: {},
    onMouseLeftDown: () => R.identity,
    onMouseLeftUp: async ({
      instance,
      config,
      context,
      annotClasses,
      ...rest
    }) => {

      instance.Tools.GenericAnnotationCreateTool.prototype.mouseLeftDown.apply(context.tool, context.args);

      
  const { tool } = context;

  let annotation;

  if (!_.isEmpty(tool.annotation)) {
    // On create set to signer
    let currentSignerId = instance.getSelectedSigner();
    let signer = instance.getSignerById(currentSignerId) || _.head(instance.getSigners());

    if (!signer) {
      console.debug('No signer found');
      instance.setToolMode('AnnotationEdit');
      return;
    } else {
      currentSignerId = signer.id; // Needed to prevent "Select a Signer" on annotation popup menu
    }

    const color = signer ? [signer.color.R, signer.color.G, signer.color.B, signer.color.A] : undefined;

    const fullName = parseName({
      lastName: '',
      ...signer,
    });


    const customdata = {
      ...tool.annotation.CustomData,
      signerId: currentSignerId,
      color,
      name: fullName,
      fullName,
    };

    tool.annotation.CustomData = customdata;
    tool.annotation.custom = customdata;
    const width = 60;
    const height = 16;
    tool.annotation.Width = width;
    tool.annotation.Height = height;
    tool.annotation.X -= width / 2;
    tool.annotation.Y -= height / 2;
    tool.annotation.FontSize = defaultFontSize;
    tool.annotation.MaintainAspectRatio = true;

    annotation = tool.annotation;
  }

  instance.Tools.GenericAnnotationCreateTool.prototype.mouseLeftUp.apply(context.tool, context.args);

  if (annotation) {
    instance.annotManager.redrawAnnotation(annotation);
    instance.setToolMode('AnnotationEdit');
    instance.annotManager.deleteAnnotation(annotation);
  }
    },
    onAnnotationAdded: createTextField({
      name: `${name}Template`,
      annotClassName: `${name}TemplateFreeTextAnnot`,
    }),
  }),
  registerAnnotationType({ elementName: 'template', annotClassName: `${name}TemplateFreeTextAnnot` }),
  registerTool({
    annotClassName: `${name}TemplateFreeTextAnnot`,
    toolName: `${name}TemplateTool`,
    buttonImage,
    buttonName: `${name}Tool`,
    tooltip: `${_.startCase(name)} Tag ${hotKey ? `(${hotKey})` : ''}`,
    colorPalette: [],
    showColor: 'never',
  }, { clearOnDraw: false, hotKey, toolGroup })
);

export default createTemplateTool;
