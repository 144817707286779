import { tagTypes } from '@enotarylog/tag-utils';
import { requiredArrow as requiredArrowSVG } from '@enotarylog/tag-utils';

/*
  This function is used for cleaning/removing required arrow elements that we appended to
  the DOM. We remove them from the page container and from our local array of required
  arrow elements.
*/
export const cleanRequiredArrow = (instance, annotId, annotPageNumber) => {
  // We need to substract 1 since the page containers are zero-indexed.
  const clickedPage = annotPageNumber - 1;

  const pageContainer = instance.iframeWindow?.document?.getElementById(
    `pageContainer${clickedPage}`
  );

  const renderedArrow = instance.iframeWindow?.document?.getElementById(
    `requiredArrow-${annotId}`
  );

  if (renderedArrow) {
    pageContainer.removeChild(renderedArrow);
  }

  instance.removeRequiredArrowElements(clickedPage, annotId);
};

/*
  This function is used for toggling the red arrows that indicate which tags are required.
  These red arrows are appended as custom DOM elements per each tag set as required. These
  arrows are removed when a required tag is 'selected', and removed when 'deselected'.
*/
export const toggleRequiredArrow = (instance, annot, showArrow) => {
  // Cleaning up previous instances of the arrow to avoid duplicated elements.
  cleanRequiredArrow(instance, annot.Id, annot.PageNumber);

  // If we didn't have to show the arrow, do nothing. Otherwise, append the arrow.
  if (!showArrow) {
    return;
  }

  const annotCoordinates = {
    x: annot.X,
    y: annot.Y
  }

  const clickedPage = annot.PageNumber - 1;

  const pageContainer = instance.iframeWindow?.document?.getElementById(
    `pageContainer${clickedPage}`
  );

  const docViewer = instance.docViewer;
  const zoom = docViewer.getZoom();

  const requiredArrow = document.createElement("div");

  requiredArrow.id = `requiredArrow-${annot.Id}`;

  /*
    Generate an arrow with the appropriate size taking into consideration the current zoom value
    and annotation type/size, and also calculating values used for positioning the arrow vertically
    and horizontally.
  */
  const {
    arrow,
    arrowVerticalPosition,
    arrowHorizontalPosition
  } = transformRequiredArrow(zoom, annot);

  requiredArrow.style.position = 'absolute';
  requiredArrow.style.left = `${(annotCoordinates.x - arrowHorizontalPosition) * zoom}px`;
  requiredArrow.style.top = `${(annotCoordinates.y + arrowVerticalPosition) * zoom}px`;
  requiredArrow.style.display = 'flex';
  requiredArrow.style.zIndex = 35;

  requiredArrow.innerHTML = arrow;

  pageContainer.appendChild(requiredArrow);

  /*
    Other than appending the arrow to the DOM, we also want to store the element in our
    local array of required arrow elements. Since when the page is re-rendered all
    elements are removed, we need this copy to re-append them.
  */
  instance.setRequiredArrowElements(clickedPage, {
    element: requiredArrow,
    left: annotCoordinates.x,
    top: annotCoordinates.y,
    annotation: annot
  });
};

/*
  This function is used for scaling and translating the required arrows based on the
  current zoom value and the current height of the annotation the arrow points to.
*/
export const transformRequiredArrow = (zoom, annot) => {
  const arrowBaseHeight = 10;
  const arrowBaseWidth = 36;
  const arrowAspectRatio = arrowBaseWidth / arrowBaseHeight;

  let arrowHeight, arrowWidth, arrowVerticalPosition, arrowHorizontalPosition;

  const annotType = annot.CustomData?.type;

  /*
    If the annotation is one of the "image tags" (e.g.: signature, initials, notary stamp, etc.),
    we draw the arrow at a particular position at the left of the annotation, pointing to the
    bottom half of it.
  */
  if (annotType in tagTypes.imageTags) {
    /*
      - Height = 25% of the height of the annotation.
      - Width = calculated height times the aspect ratio based on the base dimensions of the arrow.
    */
    arrowHeight = annot.Height * 0.25;
    arrowWidth = arrowAspectRatio * arrowHeight;

    /*
      - Vertical Position = arrow located at 70% of the height of the annotation, minus half the
      height of the arrow to center it.
      - Horizontal Position = 75% of the width of the arrow.
    */
    arrowVerticalPosition = (annot.Height * 0.70) - (arrowHeight / 2);
    arrowHorizontalPosition = arrowWidth * 0.75;
  } else {
    /*
      If the annotation was not an "image tag" (e.g.: any of the other text-based annotations),
      we draw the arrow at the left of the annotation, pointing to the middle of it.
    */

    /*
      - Height = the base height of the arrow.
      - Width = the base width of the arrow.
    */
    arrowHeight = arrowBaseHeight;
    arrowWidth = arrowBaseWidth;

    /*
      - Vertical Position = arrow located in the middle of height of the annotation.
      - Horizontal Position = arrow moved units equivalent to the entire width of itself.
    */
    arrowVerticalPosition = (annot.Height / 2) - (arrowHeight / 2);
    arrowHorizontalPosition = arrowWidth;
  }

  let arrow = requiredArrowSVG;

  // The arrow SVG expects to receive values for height and width.
  arrow = arrow
    .replace(
      '{{arrowHeight}}',
      arrowHeight * zoom
    )
    .replace(
      '{{arrowWidth}}',
      arrowWidth * zoom
    );

  return { arrow, arrowVerticalPosition, arrowHorizontalPosition };
};
