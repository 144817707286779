import { hotKeys } from '../constants/hotKeys';

export default {
  panelNames: [],
  toolNames: [
    'AddBlankPage',
    'RemoveBlankPage',
    'Divider',
    // 'RemoveFormFields',
    'RemoveAllAnnots',
    'Divider',
    // 'FormFieldTools',
    'CheckboxTool',
    'RadioButtonTool',

    // 'TemplateTools',
    'Divider',
    'VaDisclaimer',
    'NotaryTools',
    'NameTools',
    'Divider',
    'StampTools',
    'CertTool',
    'WhiteOutTool',
    'StrikeOutTool',
  ],
  popupNames: [
    // 'AssignSigner',
    'SetDateFormat',
    'SetYearFormat',
    'SetRequired',
    'SetStateFormat',
    'SetAddressFormat',
  ],
  contextMenuItems: [
    'panToolButton',
  ],
  hotKeys: [
    hotKeys.F,
    hotKeys.V,
    hotKeys.R,
    hotKeys.B,
    hotKeys.N,
    hotKeys.Q,
    hotKeys.W,
    hotKeys.E,
    hotKeys.T,
    hotKeys.S,
    hotKeys.C,
    hotKeys.A,
    hotKeys.Z,
    hotKeys.X,
    hotKeys.O,
    hotKeys.L,
    hotKeys.Y,
    hotKeys['5'],
  ],
};
