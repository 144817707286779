import { TypeOptions } from "react-toastify/dist/types";

export interface ToastMessage {
    type: TypeOptions;
    message: string;
}

export interface WindowWithToast extends Window {
    toast?: any;
}

export const showToastMessage = (w: WindowWithToast, options: ToastMessage) => {
    if(w.toast){
      w.toast[options.type](options.message);
    }
    else{
      if(w.parent){
        showToastMessage(w.parent, options);
      }
    }
};
