import React from 'react';
import * as R from 'ramda';
import { injectTool } from '../../initializers/injectors';
import SetYearFormat from './component';

const registerSetYearFormat = R.pipeP(
  injectTool('SetYearFormat', ({ instance }) => ({
    type: 'customElement',
    title: 'Set Year Format',
    render: () => (
      <SetYearFormat
        annotManager={instance.annotManager}
        instance={instance}
      />
    ),
  }))
);

export default registerSetYearFormat;
