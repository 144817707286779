/* eslint-disable no-param-reassign */
import { parseDate, createContent, parseYear } from '@enotarylog/tag-utils';
import debug from 'debug';

const log = debug('vanilla:lib');

export const setStateFormat = (annot, stateFormat, signer, instanceObject) => {
  annot.CustomData.flags = annot.CustomData.flags || {};
  annot.CustomData.flags.stateFormat = stateFormat;
  annot.setCustomData({
    ...annot.CustomData,
    flags: {
      ...annot.CustomData.flags,
      stateFormat,
    },
  });

  annot.setContents(createContent(annot.CustomData, signer));
  instanceObject.annotManager.redrawAnnotation(annot);
  instanceObject.annotManager.trigger('annotationChanged', [[annot], 'modify', { imported: false, isUndoRedo: false }]);

  return annot;
};

export const setDateFormat = (annot, dateFormat, instanceObject) => {
  annot.CustomData.flags = annot.CustomData.flags || {};
  annot.CustomData.flags.dateFormat = dateFormat;
  annot.setCustomData({
    ...annot.CustomData,
    flags: {
      ...annot.CustomData.flags,
      dateFormat,
    },
  });

  annot.setContents(parseDate(new Date(), dateFormat));

  instanceObject.annotManager.redrawAnnotation(annot);
  instanceObject.annotManager.trigger('annotationChanged', [[annot], 'modify', { imported: false, isUndoRedo: false }]);

  return annot;
};

export const setYearFormat = (annot, yearFormat, instanceObject) => {
  annot.CustomData.flags = annot.CustomData.flags || {};
  annot.CustomData.flags.yearFormat = yearFormat;
  annot.setCustomData({
    ...annot.CustomData,
    flags: {
      ...annot.CustomData.flags,
      yearFormat,
    },
  });

  annot.setContents(parseYear(yearFormat));
  // parseYear for year format

  instanceObject.annotManager.redrawAnnotation(annot);
  instanceObject.annotManager.trigger('annotationChanged', [
    [annot],
    'modify',
    { imported: false, isUndoRedo: false },
  ]);

  return annot;
};

export const setAddressFormat = (annot, addressFormat, signer, instanceObject) => {
  annot.CustomData.flags = annot.CustomData.flags || {};
  annot.CustomData.flags.addressFormat = addressFormat;
  annot.setCustomData({
    ...annot.CustomData,
    flags: {
      ...annot.CustomData.flags,
      addressFormat,
    },
  });
  annot.setContents(createContent(annot.CustomData, signer));
  instanceObject.annotManager.redrawAnnotation(annot);
  instanceObject.annotManager.trigger('annotationChanged', [[annot], 'modify', { imported: false, isUndoRedo: false }]);

  return annot;
};

export const setIsRequired = (isRequired, annot, instanceObject) => {
  const instanceCustomData = JSON.parse(instanceObject.getCustomData());

  if (instanceCustomData.taggingMode === false) {

    log('locking contents');
    annot.NoMove = isRequired;
    annot.Locked = isRequired;
  }

  annot.CustomData.flags = annot.CustomData.flags || {};
  annot.CustomData.flags.required = isRequired;
  annot.setCustomData({
    ...annot.CustomData,
    ...annot.CustomData.flags,
    required: isRequired,
    flags: {
      ...annot.CustomData.flags,
      required: isRequired,
    },
  });

  instanceObject.annotManager.redrawAnnotation(annot);
  instanceObject.annotManager.trigger('annotationChanged', [[annot], 'modify', { imported: false, isUndoRedo: false }]);

  return annot;
};
