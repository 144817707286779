import { useContext, useEffect } from 'react';
import _ from 'lodash';
import debug from 'debug';
import { useLocation, useSessionStorage } from 'react-use';
import { AppStateProvider, AppStateCtx } from './AppState';
import { LoadingScreen, useApi, useParams, useQuery } from '@enotarylog/shared';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

const log = debug('hooks:AppState');


// NOTE: this `runId` is used to determine which users are in same browser tab
const _runId = uuidv4();


// hook
export const useAppState = () => {
  const appState = useContext(AppStateCtx);

  if (!appState) {
    throw new Error('useAppState must be used within AppStateProvider');
  }

  return appState;
};


// HOC
export const withAppStateProvider = (useInitState) => (Component) => (props) => {
  const [runId] = useSessionStorage('runId', _runId);
  const initialState = useInitState({ ...props, runId: runId || _runId });


  return !initialState.loading ? (
    <AppStateProvider
      runId={runId}
      {...initialState.value}
    >
      {
        initialState.loading ?
          <LoadingScreen />
          :
          <Component
            // {...props}
            {...initialState.value}
          />

      }
    </AppStateProvider>
  ) : <LoadingScreen />
};




// HOC
export const WithAppStateProviderNotary = ({ children }) => {
  const [runId] = useSessionStorage('runId', _runId);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  console.log('PARAMSA', params);
  const api = useApi();
  const nsParticipantsState = useAsync(async () => {
    const rtn = await api.getInitialData(params.requestId, params.nsId);
    return rtn;
  });

  const { error, loading } = nsParticipantsState;

  useEffect(() => {
    if (!loading && error) {
      if (error.isAxiosError) {
        if (error.response.status === 404) {
          return history.push({
            pathname: '/404',
            search: location.search,
            state: {
              ...error.response.data,
              message: 'This session was not found in our records.  Please check the url and verify it is correct. Otherwise, contact eNotaryLog for support.',
              from: {
                pathname: location.pathname,
                search: location.search
              },
            }
          })
        }
        if (error.response.status === 401) {
          return history.push({
            pathname: '/401',
            search: location.search,
            state: {
              ...error.response.data,
              message: 'You are not authorized to access this page.  Please contact eNotaryLog support.',
              from: {
                pathname: location.pathname,
                search: location.search
              },
            }
          })
        }
      }
    }
  }, [error, history, loading, location.pathname, location.search]);


  const initialState = {
    loading: nsParticipantsState.loading,
    error: nsParticipantsState.error,
    value: {
      ...(nsParticipantsState.value || {}),
      isAdminUser: true,
      signerLocation: "remote",
      userType: 'admin',
    }
  }
  // const initialState = useInitState({ ...props, runId: runId || _runId });


  return !initialState.loading && !_.isEmpty(nsParticipantsState.value) ? (
    <AppStateProvider
      runId={runId}
      {...initialState.value}
    >
      {initialState.loading && !_.isEmpty(nsParticipantsState.value) ? <LoadingScreen /> : children}
    </AppStateProvider>
  ) : <LoadingScreen />;
};



export const withUseAppState = (Component) => (props) => {
  const appState = useAppState();

  return (
    <Component
      {...props}
      appState={appState}
    />
  );
};


export default useAppState;
