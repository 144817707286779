import { WebViewerInstance, Annotations } from '@pdftron/webviewer';

export const fitText = (instance: WebViewerInstance, freeText: Annotations.FreeTextAnnotation) => {
  const { docViewer, annotManager } = instance;
  const doc = docViewer.getDocument();
  const pageInfo = doc.getPageInfo(freeText.PageNumber - 1);
  const pageMatrix = doc.getPageMatrix(freeText.PageNumber - 1);

  const origWidth = freeText.Width;
  const origHeight = freeText.Height;

  let fontSize = parseFloat(freeText.FontSize.replace('pt', ''));

  while (fontSize >= 12) {
    const cpy = annotManager.getAnnotationCopy(freeText) as Annotations.FreeTextAnnotation;

    cpy.FontSize = `${fontSize}pt`;
    cpy.fitText(pageInfo, pageMatrix);

    // text is wrapped
    if (cpy.Width > origWidth || cpy.Height > origHeight) {
      fontSize -= 0.5;
    } else {
      return fontSize;
    }
  }

  return fontSize;
};

export default fitText;