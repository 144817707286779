/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import _ from 'lodash';
import { lazy } from 'react'
import { Redirect } from 'react-router-dom';

import { mapProps } from 'recompose';

import { loadStripe } from '@stripe/stripe-js';
import { WithAppStateProviderNotary, WithServerProviderNotary } from '@enotarylog/webviewer';


import type { Routes } from '@enotarylog/shared';
const Elements = lazy(() => import('@stripe/react-stripe-js').then((mod) => ({ default: mod.Elements })));
const PresenceProvider = lazy(() => import('./lib/hooks/usePresence').then((mod) => ({ default: mod.PresenceProvider })));
const ErrorBoundary = lazy(() => import('@enotarylog/shared').then((mod) => ({ default: mod.ErrorBoundary })));
const FirebaseProvider = lazy(() => import('@enotarylog/shared').then((mod) => ({ default: mod.FirebaseProvider })));

const MockGuard =  lazy(() => import('./components/guards/Mock'));
const ApiProvider = lazy(() => import('@enotarylog/shared').then((mod) => ({ default: mod.ApiProvider })));
const composeThemeProvider = mapProps((props) => _.pick(props, ['children', 'theme']));
const ThemeProvider = lazy(() => import('@material-ui/core').then((mod) => ({ default: mod.ThemeProvider })));
const EnlThemeProvider = composeThemeProvider(ThemeProvider);

const LandingPage = lazy(() => import('./pages/Landing'));
const ForbiddenPageView = lazy(() => import('./pages/errors/ForbiddenView'));
const InfoPageLayout = lazy(() => import('./components/Layouts/InfoPageLayout'));
const NotaryRoomLayout = lazy(() => import('./components/Layouts/NotaryRoomLayout'));
const NotFound = lazy(() => import('./pages/errors/NotFoundView'));
const NotaryRoom = lazy(() => import('./NotaryRoom'));
const PaymentPage = lazy(() => import('./pages/Payments'));
const CompletedPage = lazy(() => import('./pages/Completed'));
const FailurePage = lazy(() => import('./pages/errors/VerificationFailure'));
const SessionTerminated = lazy(() => import('./pages/errors/SessionTerminated'));
const DocVerificationLoading = lazy(() => import('./pages/doc-verification-redirect-to/loading'));
const DocVerificationRetry = lazy(() => import('./pages/doc-verification-redirect-to/retry'));


const ConsumerNotaryRoom = lazy(() => import('./ConsumerNotaryRoom'));

const stripePromise = loadStripe(process.env.NX_STRIPE_API_KEY);

const searchParams = new URLSearchParams(window.location.search);

export const routes: Routes = [
  {
    exact: true,
    path: '/',
    context: [],
    layout: InfoPageLayout,
    component: LandingPage
  },
  {
    exact: true,
    path: '/notarization/loading',
    context: [],
    loading: false,
    component: DocVerificationLoading,
  },
  {
    exact: true,
    path: '/notarization/retry',
    context: [],
    loading: false,
    component: DocVerificationRetry,
  },
  {
    exact: true,
    path: '/notarization/rooms/:requestId/payments',
    context: [
      ApiProvider,
      ErrorBoundary,
      FirebaseProvider,
      PresenceProvider,
      EnlThemeProvider,
      ApiProvider,
    ],
    contextProps: ['token', 'type', 'theme', 'config'],
    layout: InfoPageLayout,
    component: (props) => <Elements stripe={stripePromise}><PaymentPage {...props} /></Elements>
  },
  {
    exact: true,
    path: '/notarization/rooms/:requestId/complete',
    context: [],
    layout: InfoPageLayout,
    component: (props) => (
      <CompletedPage
        {...props}
        willReceiveEmail={true}
      />
    )
  },
  {
    exact: true,
    path: `/notarization/:nsId/verification-failure`,
    layout: InfoPageLayout,
    component: (props) => <FailurePage {...props} />
  },
  {
    exact: true,
    path: `/notarization/:nsId/session-terminated`,
    layout: InfoPageLayout,
    component: () => <SessionTerminated />
  },
  {
    exact: true,
    path: '/404',
    layout: InfoPageLayout,
    component: () => <NotFound />
  },
  {
    exact: true,
    path: '/401',
    layout: InfoPageLayout,
    component: () => <ForbiddenPageView />
  },
  {
    exact: true,
    path: '/enterprise/room/:requestId',
    layout: NotaryRoomLayout,
    context: [
      MockGuard,
      FirebaseProvider,
      ErrorBoundary,
      EnlThemeProvider,
      ApiProvider,
    ],
    guard: [
      WithAppStateProviderNotary,
      WithServerProviderNotary
    ],
    contextProps: ['token', 'type', 'theme', 'config'],
    component: (props) => (
      <NotaryRoom
        {...props}
      />
    )
  },
  {
    exact: true,
    path: '/notarization/rooms/:requestId',
    context: [
      MockGuard,
      FirebaseProvider,
      ErrorBoundary,
      EnlThemeProvider,
      ApiProvider,
    ],
    guard: [],
    contextProps: ['token', 'type', 'theme', 'config'],
    component: (props) => (
      <ConsumerNotaryRoom
        {...props}
      />
    )
  },
  {
    path: '*',
    component: () => <Redirect to='/404' />
  }
]
