export const adminUserDisabledElements = [
  'documentControl',
  'eraserToolButton',
  'freeHandToolGroupButton',
  'linkButton',
  'menuButton',
  'miscToolGroupButton',
  'opacitySlider',
  'searchButton',
  'shapeToolGroupButton',
  'stickyToolButton',
  'strokeThicknessSlider',
  'textToolGroupButton',
  'thumbnailControl',
  'toolStylePopup',
  'viewControlsButton'
]

export const taggingModeDisabledElements = [
  'documentControl',
  'eraserToolButton',
  'freeHandToolGroupButton',
  'leftPanelTabs',
  'linkButton',
  'menuButton',
  'miscToolGroupButton',
  'opacitySlider',
  'outlinesPanel',
  'outlinesPanelButton',
  'searchButton',
  'shapeToolGroupButton',
  'signatureToolButton',
  'stickyToolButton',
  'strokeThicknessSlider',
  'textToolGroupButton',
  'thumbnailControl',
  'thumbnailsPanelButton',
  'viewControlsButton'
]

export const viewOnlyDisabledElements = [
  ...taggingModeDisabledElements,
  'deleteToolButton',
  'freeTextToolButton'
]

export const signerDisabledElements = [
  'bookmarksPanel',
  'bookmarksPanelButton',
  'contextMenuPopup',
  'documentControl',
  'eraserToolButton',
  'fontSizeSlider',
  'freeHandToolGroupButton',
  'linkButton',
  'menuButton',
  'miscToolGroupButton',
  'opacitySlider',
  'outlinesPanel',
  'outlinesPanelButton',
  'searchButton',
  'shapeToolGroupButton',
  'stickyToolButton',
  'strokeThicknessSlider',
  'textToolGroupButton',
  'thumbnailControl',
  'toolStylePopup',
  'viewControlsButton',
]

export const eNoteSelectedDisabledElements = [
  'addPage',
  'certTools',
  'ChecboxButton',
  'freeTextToolButton',
  'nameTools',
  'notaryTools',
  'RadioButton',
  'removePage',
  'signatureToolButton',
  'stampToolGroupButton',
  'toolsButton',
  'StrikeOutTool',
  'WhiteOutTool',
]

export const documentCompletionTags = [
  'NotarySealAnnotation',
  'Signature',
]
