import * as R from 'ramda';
import enableToolButtons from './initializers/enableToolButtons';
import enablePopups from './initializers/enablePopups';
import enableContextMenuItems from './initializers/enableContextMenuItems';
import registerCustomTools from './tools';
import registerPopups from './popups';
import { injectToolArr } from './initializers/injectors';
import registerPanels from './panels';
import handleTemplateDblClick from './eventListeners/templateDblClick';
import handleAnnotSelected from './eventListeners/handleAnnotSelected';
import enablePanels from './initializers/enablePanels';
import disableHotkeys from './initializers/disableHotkeys';
import { signatureReset } from './eventListeners';

export const registerTools = R.pipeP(
  injectToolArr,

  registerCustomTools,
  registerPopups,
  registerPanels,

  enablePopups,
  enableToolButtons,
  enablePanels,
  enableContextMenuItems,
  disableHotkeys,
  handleTemplateDblClick(),
  handleAnnotSelected(),
  signatureReset()
);

export default registerTools;
