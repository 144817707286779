import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/analytics';

export interface FirebaseConfig {
  apiKey: string;
  appId: string;
  authDomain: string;
  databaseURL: string;
  messagingSenderId: string;
  projectId: string;
  storageBucket: string;
  measurementId?: string;
};

export const initializeFirebase = (config: FirebaseConfig): typeof firebase => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }


  if ('measurementId' in config) {
    firebase.analytics();
  }

  (window as any).firebase = firebase;
  return firebase;
}

