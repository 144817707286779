import * as R from 'ramda';
import _ from 'lodash';
import fetch from 'isomorphic-unfetch';
import { parseName, signHere, tagTypes } from '@enotarylog/tag-utils'
import registerTool from '../initializers/registerTool';
import registerAnnotationType from '../initializers/registerAnnotationType';
import defineToolClass from '../initializers/defineToolClass';
import defineAnnotClass from '../initializers/defineAnnotClass';
import { loadImgWithDataUrl, getSvgDimensions } from '../helpers/loadImg';

const createSignatureIntialsAnnotProps = window.createSignatureInitialAnnot = (instance, svgString = signHere) => async (signerId, appendTxt = '', coords = {}) => {
  const signer = instance.getSignerById(signerId);
  const color = signer ? [signer.color.R, signer.color.G, signer.color.B, signer.color.A] : undefined;

  const fullName = parseName({
    lastName: '',
    ...signer,
  });

  const customdata = {
    signerId: signer.id,
    color,
    name: fullName,
    fullName,
  };

  const txt = (signer?.fullName ? signer?.fullName : fullName) + appendTxt;
  let coloredString = svgString
    .replace('{{color}}', `rgb(${signer.color.R}, ${signer.color.G}, ${signer.color.B}, ${signer.color.A})`)
    .replace('{{SignerName}}', txt);

  const win = window.URL || window.webkitURL || window;
  const blob = new Blob([coloredString], { type: 'image/svg+xml' });
  const url = win.createObjectURL(blob);
  const { dataUrl } = await loadImgWithDataUrl(url, getSvgDimensions(coloredString));


  // const stamp = new instance.Annotations.StampAnnotation();
  const stamp = new instance.Annotations.SignHereTagTemplateAnnotation();
  stamp.X = (coords.x || 0) - 150;
  stamp.Y = coords.y || 0;
  stamp.Width = 150;
  stamp.Height = 39.24731182795699;
  stamp.PageNumber = coords.pageNumber || 1;
  stamp.CustomData = {
    ...(stamp.CustomData || {}),
    ...customdata
  };

  stamp.ImageData = dataUrl;

  return stamp;
}

export const autoTagEnoteSignatureInitials = (instance) => async (sig, signerId) => {
  var a = await window.createSignatureInitialAnnot(instance)(signerId);

  await instance.PDFNet.initialize();
  const doc = instance.docViewer.getDocument();
  const pdfDoc = await doc.getPDFDoc()
  const page = await pdfDoc.getPage(sig.page);
  const pageHeight = await page.getPageHeight();

  // compute height width
  const ratio = a.Width / a.Height;
  const width = sig.width;
  const height = width / ratio;
  const heightDiff = sig.height - height;


  a.PageNumber = sig.page;

  a.X = sig.leftXCoord;
  a.Y = pageHeight - (sig.lowerYCoord + sig.height - (heightDiff / 2));


  a.Width = width;
  a.Height = height;
  a.CustomData.forEnote= true;
  a.CustomData.flags = a.CustomData.flags || {};
  a.CustomData.flags.required = true;

  return a;
}

// NOTE: this is used for creating a "img/stamp tag in the doc-upload"
const onMouseLeftUp = ({ imageUrl, svgPath, name, width = 300 }) => async ({ instance, context, ...rest }) => {
  instance.Tools.GenericAnnotationCreateTool.prototype.mouseLeftDown.apply(context.tool, context.args);

  const { tool } = context;

  // Capturing the default required state to be used by signature and initials tags.
  const { config: { defaultRequiredState } } = rest;

  let annotation;

  const { img } = (!_.isFunction(imageUrl)) ? await loadImgWithDataUrl(imageUrl) : await loadImgWithDataUrl(await imageUrl({ instance, context, ...rest }));

  if (!_.isEmpty(tool.annotation)) {
    const rotation = instance.docViewer.getCompleteRotation(1) * 90;
    tool.aspectRatio = img.width / img.height;
    // width = 150;
    let height = width / tool.aspectRatio;


    tool.annotation.Rotation = rotation;

    if (rotation === 270 || rotation === 90) {
      const t = height;

      height = width;
      // eslint-disable-next-line no-param-reassign
      width = t;
    }

    // On create set to signer
    // let currentSignerId = instance.getSelectedSigner();
    // let signer = instance.getSignerById(currentSignerId) || _.head(instance.getSigners());
    const signers = instance.getSigners()
    const getSigner = signers.filter((signer) => signer.type === 'notary' && !_.isNil(signer.type));

    const signerId = getSigner[0].id;
    const signer = instance.getSignerById(signerId);

    const color = signer ? [signer.color.R, signer.color.G, signer.color.B, signer.color.A] : undefined;

    const fullName = parseName({
      lastName: '',
      ...signer,
    });

    const customdata = {
      ...tool.annotation.CustomData,
      signerId: signer.id,
      color,
      name: fullName,
      fullName,
    };

    /*
      Since there are other types of image/stamp tags, we check if the one added was a signature
      or initials.
    */
    const isSignatureOrInitials = tool.annotation.Subject === "SignHereTagTemplateAnnotation" ||
      tool.annotation.Subject === "InitialHereTagTemplateAnnotation";

    /*
      If the annotation added was a signature or initials tag, we want it to be required by default.
      This, however, will change when ticket V2-4653 is implemented since the org settings will
      determine if these tags are required or not.

      For now this value comes hardcoded as a prop in the Viewer component, but in the feature
      this value will we loaded from the database.
    */
    if (isSignatureOrInitials) {
      customdata.flags = customdata.flags || {};
      customdata.flags.required = defaultRequiredState;

      /*
        Required annotations in the notary room are unmovable and uneditable, so we make sure that
        annotations that are defaulted to be required behave in the same manner.
      */
      if (defaultRequiredState) {
        tool.annotation.NoMove = true;
        tool.annotation.Locked = true;
      }
    }

    tool.annotation.CustomData = customdata;
    tool.annotation.custom = customdata;

    const svgString = svgPath;
    let coloredString = svgString;

    const upperCaseName = name.toUpperCase();

    if (Object.keys(tagTypes.imageTags).some((tagName) => tagName.includes(upperCaseName))) {
      coloredString = svgString
        .replace('{{color}}', `rgb(${signer.color.R}, ${signer.color.G}, ${signer.color.B}, ${signer.color.A})`)
        .replace('{{SignerName}}', signer?.fullName ? signer?.fullName : fullName);
      tool.annotation.CustomData.signerId = signer.id;
    } else {
      // for seal stamp Tag
      tool.annotation.CustomData.signerId = 'Notary';
    }

    const win = window.URL || window.webkitURL || window;
    const blob = new Blob([coloredString], { type: 'image/svg+xml' });
    const url = win.createObjectURL(blob);
    const { dataUrl } = await loadImgWithDataUrl(url, getSvgDimensions(coloredString));

    tool.annotation.ImageData = dataUrl;
    tool.annotation.Width = width;
    tool.annotation.Height = height;
    tool.annotation.X -= width / 2;
    tool.annotation.Y -= height / 2;
    tool.annotation.MaintainAspectRatio = true;

    annotation = tool.annotation;
  }

  instance.Tools.GenericAnnotationCreateTool.prototype.mouseLeftUp.apply(context.tool, context.args);

  if (annotation) {
    instance.annotManager.redrawAnnotation(annotation);
    instance.setToolMode('AnnotationEdit');
    instance.annotManager.selectAnnotation(annotation);
  }
};


// eslint-disable-next-line no-unused-vars
const onMouseLeftDown = () => R.identity;


const createStampTag = ({
  name,
  imageUrl,
  svgPath,
  width,
}) => R.pipeP(
  // define annot class
  defineAnnotClass({
    className: `${name}Annotation`,
    baseClassName: 'StampAnnotation',
    customData: { type: _.toUpper(`${name}Annotation`), imageUrl },
    methods: {
      // NOTE: this doesn't seem to do shit
      onDoubleClicked: ({ instance }) => async function () {
        const sigTool = instance.docViewer.getTool('AnnotationCreateSignature');
        const [sig] = sigTool.getSavedSignatures();
        const res = await fetch(this.CustomData.imageUrl);
        const svgString = await res.text();


        if (!sig) {
          return;
        }

        const sigDataUrl = await sigTool.getPreview(sig);
        const newSvgStr = svgString.replace('<image xlink:href=""', `<image xlink:href="${sigDataUrl}"`);
        const DOMURL = window.URL || window.webkitURL;
        const svg = new Blob([newSvgStr], { type: 'image/svg+xml;charset=utf-8' });
        const svgurl = DOMURL.createObjectURL(svg);

        const { dataUrl /* img */ } = await loadImgWithDataUrl(svgurl, getSvgDimensions(newSvgStr));

        this.ImageData = dataUrl;

        return instance.annotManager.redrawAnnotation(this);
      },
    },
  }),

  // define tool class that uses annot class
  defineToolClass({
    className: `${name}Tool`,
    annotClassName: `${name}Annotation`,
    baseClassName: 'GenericAnnotationCreateTool',
    onMouseLeftDown: onMouseLeftDown(),
    // onMouseMove: ({ instance, context }) => instance.Tools.AnnotationSelectTool.prototype.mouseMove.apply(context.tool, context.args),
    onMouseLeftUp: onMouseLeftUp({ imageUrl, svgPath, name, width }),
  }),

  // register the custom annot class to webviewer
  registerAnnotationType({
    elementName: name,
    annotClassName: `${name}Annotation`,
  })
);


export const createStampTemplateTag = ({
  name,
  imageUrl,
  svgPath,
  buttonImage,
  tooltip,
  width,
  hotKey,
  toolGroup,
}) => R.pipeP(
  // define annot class
  createStampTag({
    name,
    imageUrl,
    svgPath,
    buttonImage,
    width,
    toolGroup,
  }),

  registerTool({
    type: 'toolButton',
    toolName: `${name}Tool`,
    buttonImage,
    dataElement: name,
    tooltip: `${tooltip} ${hotKey ? `(${hotKey})` : ''}`,
    // hidden: ['tablet', 'mobile'],
  }, { clearOnDraw: false, hotKey, toolGroup })
);

export default createStampTemplateTag;
