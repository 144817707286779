import * as R from 'ramda';

import { addPageIcon, removePageIcon } from '../constants/icons';
import { injectTool } from '../initializers/injectors';

const addBlankPage = {
  type: 'actionButton',
  img: addPageIcon,
  title: 'Add Blank Page',
  dataElement: 'addPage',
  // TODO: add a handler for docViewer.on('addBlankPage', () =>{})
  onClick: ({ instance }) => async () => {
    const currPageCount = instance.docViewer.getPageCount();
    const originalPageCount = instance.getOriginalPageCount();
    const currDocId = instance.getDocId();
    const currBlankPages = currPageCount - originalPageCount;

    return instance.docViewer.trigger('blankPagesAdded', [currDocId, currBlankPages + 1]);
  },
};


const removeBlankPage = {
  type: 'actionButton',
  img: removePageIcon,
  title: 'Remove Page',
  dataElement: 'removePage',
  // TODO: add a handler for docViewer.on('removeBlankPage', () =>{})
  onClick: ({ instance }) => async () => {
    const currPageCount = instance.docViewer.getPageCount();
    const originalPageCount = instance.getOriginalPageCount();
    const currDocId = instance.getDocId();
    const currBlankPages = currPageCount - originalPageCount;

    if (currBlankPages === 0) {
      return;
    }

    return instance.docViewer.trigger('blankPagesRemoved', [currDocId, currBlankPages - 1]);
  },
};


const createStampAnnotTool = R.pipeP(
  injectTool('AddBlankPage', addBlankPage),
  injectTool('RemoveBlankPage', removeBlankPage)
);

export default createStampAnnotTool;
