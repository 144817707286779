import {
  eNotePalette,
  defaultSignaturePalette,
  defaultTextPalette,
  adminUserDisabledElements,
  taggingModeDisabledElements,
  viewOnlyDisabledElements,
  signerDisabledElements
} from "@enotarylog/tag-utils";

const wvConfig = ({ 
  viewOnly = false, 
  isAdminUser = false, taggingMode = false, isEsign = false, disableElements: _disabled = [], isEnote=false } = {}) => {
  let disableElements = taggingMode 
    ? taggingModeDisabledElements 
    : viewOnly 
    ? viewOnlyDisabledElements 
    : isAdminUser 
    ? adminUserDisabledElements 
    : signerDisabledElements;

  if (isEsign) {
    disableElements.push('leftPanel');
    disableElements.push('leftPanelButton');
  }

  if (isEnote && !isAdminUser) {
    // This will hide if eNote exists in session to enforce single color for signature that Signer cannot edit
    // NOTE: This still displays for the Notary. TODO: Confirm w/product if Notary should still have this feature
    // Bug exists where user will be able to change the Typed text to Blue --> Customizing palette doesn't affect the Signature Typed Text after it is set. 
    disableElements.push('annotationStyleEditButton')
  }

  return {
    path: '/lib',
    fullAPI: true,
    config: '/wv-configs/vanilla-config.js',
    custom: {
      taggingMode,
      /*
        In ticket V2-4883 it was requested that for the 2022.11 release "text" based signatures
        use the "old" light blue color and signatures/initials use the "new" dark blue color,
        that's why the separation of default color palettes.
      */
      colorPalette: [
        {
          toolNames: ['AnnotationCreateSignature'],
          colors: isEnote ? eNotePalette : defaultSignaturePalette,
        },
        {
          toolNames: ['AnnotationCreateFreeText'],
          colors: isEnote ? eNotePalette : defaultTextPalette,
        },
      ],
      fitMode: 'FitWidth',
      layoutMode: 'Continuous',
      disableFeatures: [
        'TextSelection',
        'NotesPanel',
        'FilePicker',
        'Redaction',
        'Download',
        'Print',
        'ThumbnailReordering',
      ],
      disableTools: [
        'AnnotationCreatePolygon',
        'AnnotationCreateTextHighlight',
        'AnnotationCreateTextUnderline',
      ],
      enableElements: [],
      disableElements: [
        ...disableElements,
        ..._disabled
      ],
    },
  };
};

export default wvConfig;
