import React from 'react';

import * as R from 'ramda';
import _ from 'lodash';
import { parseName } from '@enotarylog/tag-utils';
import { injectTool } from '../initializers/injectors';
import { sleep } from '@enotarylog/shared';

export class SelectSigner extends React.Component {
  constructor(props, ctx) {
    super(props, ctx);

    this.state = {
      signers: props.signers,
      selectedSigner: null,
      disabled: false, // As of V2-6754 we temporarily disable to avoid quick signer changes.
    };
  }

  componentDidMount() {
    const { instance } = this.props;

    const signers = this.props.signers;
    const selectedSigner = this.props.selectedSigner;

    this.setState({
      signers,
      selectedSigner,
    });

    instance.annotManager.on('signersChanged', () => this.setState({ signers }));
    instance.annotManager.on('selectedSignerChanged', (signer) => this.setState({ selectedSigner: signer }));
  }

  /*
    Updating the list of signers in the SelectSigner dropdown.
    This is needed, for example, when a new signer is added through the Notary Journal.
  */
  componentDidUpdate() {
    if(!_.isEqual(this.props.signers, this.state.signers)){
      this.setState((prevState) => ({
        signers: this.props.signers,
        selectedSigner: prevState.selectedSigner,
      }));
    }
  }

  render() {
    return (
      <select
        className='signer-select'
        value={this.state.selectedSigner}
        onChange={async (evt) => {
          this.props.instance.annotManager.trigger('setSelectedSigner', evt.target.value);

          /*
            When a signer is selected we want to disable the dropdown temporarily. This is to
            avoid having the Notary making quick changes.
          */
          if (evt.target.value !== '-1') {
            this.setState({ disabled: true });

            await sleep(3000);

            this.setState({ disabled: false });
          }
        }}
        disabled={this.state.disabled}
      >
        <option value='-1'>Select Active Signer</option>
        {
          _.map(this.state.signers, (signer) => {
            if (!signer || signer.type === 'remotesigner') {
              return null;
            }

            return (
              <option
                key={signer.id}
                value={signer.id}
              >
                {parseName(signer)}
              </option>
            );
          })
        }
      </select>
    );
  }
}

const setCurrentSigner = ({ instance }) => ({
  type: 'customElement',
  title: 'Select Signer',
  dataElement: 'selectSigner',
  render: () => (
    <SelectSigner
      signers={instance.getSigners()}
      instance={instance}
    />
  ),
});

const registerSelectSigner = R.pipeP(
  injectTool('SelectSigner', setCurrentSigner)
);

export default registerSelectSigner;
