/**
 * Generates a File object from a XFDF string.
 * @param {*} instance WebViewer instance
 * @param {string} docId Document ID
 * @returns {Promise<File>} Promise with the generated File object
 */
export const generateFileFromXFDF = async (instance, docId) => {
  const annotList = instance.annotManager.getAnnotationsList();

  const xfdfString = await instance.annotManager.exportAnnotations({ annotList });

  const enc = new TextEncoder(); // always utf-8
  const xfdfUtf8 = enc.encode(xfdfString);

  const xfdfBlob = new Blob([xfdfUtf8], { type: 'application/xml' });
  const file = new window.File([xfdfBlob], `${docId}.xfdf`, {
    type: 'application/xml',
  });

  return file;
};
