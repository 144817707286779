import React, { Component } from 'react';


// eslint-disable-next-line react/prefer-stateless-function
export default class TestPanel extends Component {
  render() {
    return (
      <div>
        TODO: Custom panel here
      </div>
    );
  }
}
