import * as R from 'ramda';
import registerFormFieldTools from './formFields';
import registerTemplateTools from './templating';
import registerStampAnnotTool from './stamps';
import registerNotaryCertTool from './notaryCerts';
import registerAddRemovePage from './addRemovePage';
import registerRemoveFormFields from './removeFormFields';
import registerRemoveAllAnnots from './removeAllAnnots';
import registerSelectSigner from './selectSigner';
import registerShowSigner from './showSigner';
import registerLockCheckbox from './lock';
import registerVaDisclaimer from './vaDisclaimer';
import registerWhiteOut from './whiteout';
import registerStrikeOutTool from './strikeout';


const registerTools = R.pipeP(
  registerSelectSigner,
  registerShowSigner,
  registerAddRemovePage,
  registerRemoveFormFields,
  registerRemoveAllAnnots,
  registerNotaryCertTool,
  registerFormFieldTools,
  registerTemplateTools,
  registerStampAnnotTool,
  registerLockCheckbox,
  registerVaDisclaimer,
  registerStrikeOutTool,
  registerWhiteOut,

  ({ instance, annotClasses, ...rest }) => {
    // eslint-disable-next-line no-param-reassign
    instance.Annotations = { ...instance.Annotations, ...annotClasses };

    return {
      instance,
      annotClasses,
      ...rest,
    };
  }
);

export default registerTools;
