import { WebViewerInstance, Annotations } from '@pdftron/webviewer'
import { states } from '../../constants/states';
import _ from 'lodash';
import { tagTypes } from '../../constants/tagTypes';
import { createFreeTextAnnot, ICreateAnnotationOptions } from '../createAnnotation';
import { StateAbbr, AddressTagKeys, IAddressInfo } from '../../types';

const { abbrToFull } = states;



/**
 * Creates a freetext annotation
 * @param instance 
 * @param addressInfoText 
 * @param annot 
 */
export const createAddressFreeText = async (
  instance: WebViewerInstance,
  addressInfoText: string,
  annot: Annotations.FreeTextAnnotation,
  options: ICreateAnnotationOptions | Record<string, any> = {}
) => {
  if (addressInfoText) {
    return createFreeTextAnnot(instance, addressInfoText, annot, options);
  }

  console.warn('address.createAnnotation: address text was empty')
  return null;
};




// formats address info into string and then creates a freetext annotation
export const createAddressAnnotation = async (instance: WebViewerInstance, addressInfo: IAddressInfo, annot: Annotations.FreeTextAnnotation) => {
  const { address1, address2, city, state, zip, } = addressInfo;
  let formattedAddress = address1;

  if (annot.CustomData.flags.addressFormat === 'full') {
    formattedAddress = `${address1}, ${address2 ? `${address2}, ` : ''}${city}, ${state}, ${zip}`;
  }

  return createAddressFreeText(instance, formattedAddress, annot);
};





export const createCityAnnotation = createAddressFreeText;


export const createStateAnnotation = async (instance: WebViewerInstance, state: StateAbbr, annot: Annotations.FreeTextAnnotation) => {
  const fmt = _.get(annot, 'CustomData.flags.stateFormat');
  const formattedState = (fmt === 'full') ? abbrToFull[state] : state;
  return createAddressFreeText(instance, formattedState, annot);
};


export const createZipAnnotation = createAddressFreeText;


export const handleCreateAddressAnnotation = (instance: WebViewerInstance, addressField: IAddressInfo | StateAbbr | string, tag: Annotations.FreeTextAnnotation, addressTagType: AddressTagKeys) => {
  if (addressTagType === 'ADDRESSTEMPLATE') {
    const createAddressFieldAnnotation = tagTypes.addressTags[addressTagType].createAddressFieldAnnotation as (inst: WebViewerInstance, addressField: IAddressInfo, tag: Annotations.FreeTextAnnotation) => Promise<Annotations.FreeTextAnnotation | null>;
    return createAddressFieldAnnotation(instance, addressField as IAddressInfo, tag);
  }
  if (addressTagType === 'STATETEMPLATE') {
    const createAddressFieldAnnotation = tagTypes.addressTags[addressTagType].createAddressFieldAnnotation as (inst: WebViewerInstance, addressField: StateAbbr, tag: Annotations.FreeTextAnnotation) => Promise<Annotations.FreeTextAnnotation | null>;
    return createAddressFieldAnnotation(instance, addressField as StateAbbr, tag);
  }
  else {
    const createAddressFieldAnnotation = tagTypes.addressTags[addressTagType].createAddressFieldAnnotation as (inst: WebViewerInstance, addressField: string, tag: Annotations.FreeTextAnnotation) => Promise<Annotations.FreeTextAnnotation | null>;
    return createAddressFieldAnnotation(instance, addressField as string, tag);
  }
};
