import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as R from 'ramda';
import { useCustomCompareEffect } from 'react-use';
import { constants } from '@enotarylog/tag-utils';
const { tagTypes } = constants;

export function AddressPromptModal(props) {
  const [address, setAddress] = useState(null);

  const checkIfFieldExists = (addressField) => R.isNil(addressField) ? '' : props.onSubmit(addressField);

  const getTitle = (type) => tagTypes.addressTags[type].title;

  const getBody = () => (
    <p className='m-0 d-flex justify-content-center'>
      <input
        type='text'
        name='text'
        className='text-prompt-address'
        value={address}
        onChange={R.pipe(R.path(['target', 'value']), setAddress)}
      />
    </p>
  );

  useCustomCompareEffect(() => {
    if (props.show) {
      const isNotary = !Number.isNaN(+props?.annotCustomData?.signerId);
      const signer = (isNotary) ? props.notary : props.currentUser;


      if (props.annotCustomData.type === 'ADDRESSTEMPLATE') {
        props.onSubmit({
          address1: signer?.user?.address1,
          address2: signer?.user?.address2,
          city: signer?.user?.city,
          state: signer?.user?.state,
          zip: signer?.user?.zip,
        });
      } else if (isNotary) {
        setAddress(checkIfFieldExists(signer[tagTypes.addressTags[props.annotCustomData.type].notaryField]));
      } else {
        const tagType = tagTypes.addressTags[props.annotCustomData.type];

        setAddress(checkIfFieldExists(signer?.user[tagType.field]));
      }
    }
  }, [props], (prevDeps, nextDeps) => _.isEqual(prevDeps[0].show, nextDeps[0].show));

  return (
    <Modal
      style={{ zIndex: 3000 }}
      size='sm'
      show={props.show}
      onHide={() => props.onHide}
      variant='primary'
      centered
    >
      <Modal.Header>
        {getTitle(props.annotCustomData.type)}
      </Modal.Header>

      <Modal.Body>
        <p className='m-0'>
          {getBody(props.annotCustomData.type)}
        </p>
      </Modal.Body>

      <Modal.Footer>
        <button
          type='button'
          className='btn btn-sm btn-primary col-sm-4'
          onClick={() => {
            props.onHide();
            setAddress(null);
          }}
        >
          Cancel
        </button>
        <button
          type='button'
          disabled={R.isEmpty(address)}
          className='btn btn-sm btn-primary col-sm-4'
          onClick={() => {
            props.onSubmit(address);
            setAddress(null);
          }}
        >
          Confirm
        </button>

      </Modal.Footer>
    </Modal>
  );
}
export default AddressPromptModal;


AddressPromptModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

AddressPromptModal.defaultProps = {
  show: false,
};
