/* eslint-disable no-param-reassign */
import { is } from 'bluebird';
import _ from 'lodash';

const handleAnnotSelect = ({ instance }) => async (annot) => {
  // Include a desired logic when an annotation is selected.
};

const handleAnnotSelected = () => ({ instance, ...rest }) => {
  const handleSelected = handleAnnotSelect({ instance, ...rest });

  instance.annotManager.off('annotationSelected', handleSelected);
  instance.annotManager.on('annotationSelected', handleSelected);

  return {
    instance,
    ...rest,
  };
};

export default handleAnnotSelected;
