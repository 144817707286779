import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { initializeFirebase, FirebaseConfig } from '../clients/firebase';
import { mapProps } from 'recompose';
import { pick } from 'ramda';

const FirebaseContext = createContext<typeof firebase>({} as typeof firebase);

export const useFirebase = () => {
  const ctx = useContext(FirebaseContext);
  if (!ctx) {
    throw new Error('useFirebase must be rendered within FirebasebaseProvider.');
  }
  return ctx;
}

export const withFirebase = <P extends Record<string, any>>(Component: React.ComponentType<P>): React.FunctionComponent<P & { firebase: typeof firebase }> => {

  function WithFirebaseComponent(props: P) {
    const firebase = useFirebase();

    return (
      <Component
        {...props}
        firebase={firebase}
      />
    );
  }

  return WithFirebaseComponent;
}

function FirebaseProviderBase({ children, config }: { children: React.ReactNode, config: FirebaseConfig }) {
  const firebase = useMemo(() => initializeFirebase(config), [config]);

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
}

FirebaseProviderBase.propTypes = {
  config: PropTypes.shape({
    apiKey: PropTypes.string.isRequired,
    appId: PropTypes.string.isRequired,
    authDomain: PropTypes.string.isRequired,
    databaseURL: PropTypes.string.isRequired,
    messagingSenderId: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    storageBucket: PropTypes.string.isRequired,
    measurementId: PropTypes.string
  }).isRequired,
  children: PropTypes.node.isRequired,
};


const composeComponent = mapProps(pick(['config', 'children']));
export const FirebaseProvider = composeComponent(FirebaseProviderBase as React.FunctionComponent<{ children: React.ReactNode, config: FirebaseConfig }>);
