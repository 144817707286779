import { useQuery } from './useQuery';
import { isAColor } from '../utils/isAColor';

// Don't forget to URL Encode the hex color value: # = %23
// Valid:   %234455AA
// Invalid: #4455AA
export function useCustomColors() {
  let { primary, secondary, bgColor } = useQuery();

  if (!isAColor(primary)) {
    primary = undefined;
  }
  if (!isAColor(secondary)) {
    secondary = undefined;
  }

  if (!isAColor(bgColor)) {
    bgColor = undefined;
  }

  return {
    primaryColor: primary,
    secondaryColor: secondary,
    background: bgColor
  };
}
