import React from 'react';
import styled from 'styled-components';

const LoadingMessage = styled.div`
  @font-face {
    font-family: 'Cera';
    src: url('/static/fonts/Cera-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Cera';
    src: url('/static/fonts/Cera-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cera';
    src: url('/static/fonts/Cera-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cera';
    src: url('/static/fonts/Cera-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cera';
    src: url('/static/fonts/Cera-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
  }

  & {
    text-align: center;
    font-family: Cera, sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 300ms ease;
  }
  .loader {
    display: inline-block;
  }
  p {
    margin-bottom: 0;
    line-height: 1.525;
  }
  &.hide-loader {
    opacity: 0;
  }
  & .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0.5rem 1.5rem;
    line-height: calc(35px - 1rem - 2px);
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  &  .btn.btn-primary {
    background-color: #006f83;
    border-color: #006f83;
    color: #fff;
  }
  & .btn.btn-secondary {
    background-color: #006f83;
    border-color: #006f83;
    color: #fff;
  }
  & .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
`;

class Waiting extends React.Component {
  messages = [
    'Please wait while we process your images',
    'The robots are working...',
    'The gears are turning...',
    'We are crunching the numbers...',
    'Almost done...',
    'We are almost there...',
  ]

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleRetry = () => {
    if (window) {
      window.parent && window.parent.postMessage('retry', '*');
      window.postMessage('retry', '*');
    }
  }


  componentDidMount(){
    this.interval = setInterval(() => {
      this.handleRetry();
    }, 1000)
  }
  componentWillUnmount(){
    clearInterval(this.interval);
  }


  render() {
    return (
      <LoadingMessage>
      </LoadingMessage>
    );
  }
}

export default Waiting;
