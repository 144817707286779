import _ from 'lodash';
import * as R from 'ramda';
import { 
  createContent, 
  parseName, 
  toFullName, 
  parseField, 
  tagTypes, 
  fitText as getFontSizeToFit, 
  orangeTagColor, 
  defaultFontSize  
} from '@enotarylog/tag-utils';

const createTextField = ({ name, annotClassName }) => ({ instance, annotClasses, ...rest }) => {
  const { Annotations, docViewer } = instance;
  const annotManager = instance.docViewer.getAnnotationManager();
  const checkIfDateNeeded = (type) => {
    if (type in tagTypes.dateTags) {
      return tagTypes.dateTags[type].dateFormat;
    }

    return undefined;
  };

  const checkIfYearNeeded = (type) => {
    if(type in tagTypes.yearTags) {
      return tagTypes.yearTags[type].yearFormat;
    }

    return undefined;
  }

  const checkIfStateNeeded = (type) => {
    if (type in tagTypes.addressTags) {
      return tagTypes.addressTags[type].stateFormat;
    }
  };
  const checkIfAddressNeeded = (type) => {
    if (type in tagTypes.addressTags) {
      return tagTypes.addressTags[type].addressFormat;
    }
  };


  return async (rectAnnot, custom = {}) => {
    const {
      type = _.toUpper(name),
      value = '',
      flags = {
        readOnly: true,
        multiline: false,
        edit: true,
        required: false,
        dateFormat: checkIfDateNeeded(type),
        yearFormat: checkIfYearNeeded(type),
        stateFormat: checkIfStateNeeded(type),
        addressFormat: checkIfAddressNeeded(type),
      },
    } = (custom || {});

    // Capturing the default required state to be used by certain types of text-based annotations.
    const { config: { defaultRequiredState } } = rest;

    // console.debug('type', type);
    const pageIndex = (rectAnnot?.PageNumber) ? rectAnnot.PageNumber - 1 : docViewer.getCurrentPage() - 1;


    // const zoom = docViewer.getZoom();
    const CustomAnnot = annotClasses[annotClassName];
    const textAnnot = new CustomAnnot();

    const rotation = docViewer.getCompleteRotation(pageIndex + 1) * 90;

    textAnnot.Rotation = rotation;
    textAnnot.X = rectAnnot.X;
    textAnnot.Y = rectAnnot.Y;
    textAnnot.Width = rectAnnot.Width;
    textAnnot.Height = rectAnnot.Height;
    textAnnot.setPadding(new Annotations.Rect(0, 0, 0, 0));
    textAnnot.Author = instance.annotManager.getCurrentUser();

    // get currently selected signer.
    const signers = instance.getSigners()
    const getSigner = signers.filter((signer) => signer.type === 'notary' && !_.isNil(signer.type));

    const signerId = getSigner[0].id;
    const signer = instance.getSignerById(signerId);

    // NOTE: This isn't the finalized logic
    if (!signer || !signer.id) {
      await annotManager.deleteAnnotation(rectAnnot, true);

      return annotManager.deselectAllAnnotations();
    }

    textAnnot.custom = {
      ...custom,
      ...rectAnnot.CustomData,
      type,
      value,
      flags,
      fieldType: type,
      signerId: signer ? signer.id : 'Notary',
      id: rectAnnot.Id,
      color: signer ? [signer.color.R, signer.color.G, signer.color.B, signer.color.A] : [17, 8, 236, 0.5],
      author: annotManager.getCurrentUser(),
      name: signer ? parseName(signer) : 'Notary',
    };

    // Since there are other types of text fields, we check if it was a Date tag.
    const isDateTag = textAnnot.Subject === "DateTemplateFreeTextAnnot";

    /*
      If the annotation added was a Date tag, we want it to be required by default.
      This, however, will change when ticket V2-4653 is implemented since the org settings will
      determine if these tags are required or not.

      For now this value comes from a hardcoded prop in the Viewer component, but it will be
      fetched from the database later.
    */
    if (isDateTag) {
      textAnnot.custom.flags.required = defaultRequiredState;

      /*
        Required annotations in the notary room are unmovable and uneditable, so we make sure that
        annotations that are defaulted to be required behave in the same manner.
      */
      if (defaultRequiredState) {
        textAnnot.NoMove = true;
        textAnnot.Locked = true;
      }
    }

    textAnnot.custom.fullName = toFullName(textAnnot.custom.name);
    textAnnot.custom.fullName !== 'Notary FirstName Notary MiddleName Notary LastName' && localStorage.setItem('fullName', textAnnot.custom.fullName);


    textAnnot.CustomData = textAnnot.custom;

    let contents = createContent(textAnnot.CustomData, signer);
    const contentEmpty = R.isNil(contents) || R.isEmpty(contents);

    if (R.not(type in tagTypes.imageTags) && R.not(type in tagTypes.fieldTags) && R.not(contentEmpty)) {
      textAnnot.setRichTextStyle({
        0: {
          'text-decoration': 'underline',
          color: '#000000',
        }
      });
    } else {
      textAnnot.setRichTextStyle({
        0: {
          color: '#000000',
        }
      });
    }

    textAnnot.FontSize = defaultFontSize;
    textAnnot.TextColor = new Annotations.Color(0, 0, 0);
    textAnnot.FillColor = signer ? new Annotations.Color(...[signer.color.R, signer.color.G, signer.color.B, signer.color.A]) : new Annotations.Color(17, 8, 236, 0.5);
    textAnnot.StrokeThickness = 0;
    textAnnot.TextAlign = 'left';
    textAnnot.PageNumber = rectAnnot?.PageNumber || (pageIndex + 1);
    textAnnot.LockedContents = true;
    textAnnot.ContentLocked = true;

    if (R.isNil(contents) || R.isEmpty(contents)) {
      // Nil or empty give no field entered
      contents = parseField(type);
    }

    if (R.isEmpty(contents) && (type === 'SIGNATURE' || type === 'INITIALS' || type === 'CHECK' || type === 'TEXT')) {
      const signer = instance.getSignerById(textAnnot.CustomData.signerId) || {};
      const fullName = parseName(signer);
      const lbl = _.upperFirst(_.lowerCase(type));

      contents = `${lbl}: ${fullName}`;
    }

    textAnnot.setContents(contents);
    textAnnot.TextAlign = 'center';
    textAnnot.FontSize = defaultFontSize;
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `normal ${textAnnot.FontSize} ${textAnnot.Font}`;
    const metrics = context.measureText(
      textAnnot.custom.fullName
          === 'Notary Firstname Notary Middlename Notary Lastname'
          && annotClassName === 'FullNameTemplateFreeTextAnnot'
        ? 'Notary'
        : contents
    );

    textAnnot.Width = Math.round(metrics.width * 0.8);
    if (rotation === 270 || rotation === 90) {
      const height = textAnnot.Height;
      const width = textAnnot.Width;
      textAnnot.Height = width;
      textAnnot.Width = height;
    }
    textAnnot.autoSizeProperties = {
        expandWidth: true,
        shrinkWidth: true
    };

    textAnnot.setPadding(new instance.Annotations.Rect(0, 0, 0, 0));
    textAnnot.Author = instance.annotManager.getCurrentUser();

    const newFontSize = getFontSizeToFit(instance, textAnnot);

    textAnnot.FontSize = `${newFontSize}pt`;

    // NOTE: for form fields use orange color.
    if (type in tagTypes.fieldTags) {
      textAnnot.custom.color = orangeTagColor; 
    }
    if(textAnnot.custom.name === 'Notary firstname Notary middlename Notary lastname' && annotClassName === 'FullNameTemplateFreeTextAnnot') {
      textAnnot.SY = 'Notary';
    }

    // await annotManager.deleteAnnotation(rectAnnot, true);
    await annotManager.addAnnotation(textAnnot, false);
    await annotManager.deselectAllAnnotations();
    await annotManager.redrawAnnotation(textAnnot);
    await annotManager.selectAnnotation(textAnnot);
  };
};

export default createTextField;
