/* eslint-disable no-param-reassign */
/* eslint-disable max-classes-per-file */
import * as R from 'ramda';
import { whiteOutToolIcon } from '../constants/icons';
import { injectTool } from '../initializers/injectors';


const defineWhiteoutAnnot = async ({ instance, toolClasses, annotClasses, tools, ...rest }) => {
  const { Annotations, annotManager } = instance;

  // define custom annot class
  // eslint-ignore-next-line
  class WhiteOutAnnotation extends Annotations.RectangleAnnotation {
    // ...
    constructor(...args) {
      super(...args);
      this.Subject = 'WhiteOutAnnotation';
    }

    // rename `color` attribute to `fe-border` so the grey border is gone in the serialized xfdf (border wont be shown in sealed pdf)
    serialize(...args) {
      const el = super.serialize(...args);

      // const color = el.getAttribute('color');
      if (this.Color) {
        const colorStr = this.Color.toHexString();

        el.setAttribute('fe-border', colorStr);
        el.setAttribute('color', '#FFFFFF');
      }

      this.ToolName = 'WhiteOutTool';

      // console.log('el', el);

      return el;
    }

    // look for `fe-border` attribute in the element and use it for fe so grey border is shown in webviewer
    deserialize(...args) {
      super.deserialize(...args);
      const [el] = args;

      if (el.getAttribute('fe-border')) {
        const colorStr = el.getAttribute('fe-border');

        this.Color = new Annotations.Color(colorStr);
      }
    }
  }

  // WhiteOutAnnotation.prototype.elementName = 'square';

  // register the custom annotation
  annotManager.registerAnnotationType('WhiteOutAnnotation', WhiteOutAnnotation);

  annotClasses.WhiteOutAnnotation = WhiteOutAnnotation;
  instance.Annotations.WhiteOutAnnotation = WhiteOutAnnotation;

  return {
    instance,
    annotClasses,
    toolClasses,
    tools,
    ...rest,
  };
};

const defineWhiteoutTool = async ({ instance, toolClasses, annotClasses, tools, ...rest }) => {
  const { Annotations, Tools, docViewer, annotManager } = instance;

  const { WhiteOutAnnotation } = Annotations;

  if (!WhiteOutAnnotation) {
    throw new Error('Tools.WhiteOutAnnotation is not defined');
  }

  // define custom tool class
  class WhiteOutTool extends Tools.GenericAnnotationCreateTool {
    constructor(docViewer) {
      super(docViewer, WhiteOutAnnotation);
    }


    handleAnnotationAdded = (annot) => {
      annotManager.selectAnnotation(annot);
    };

    switchIn() {
      this.on('annotationAdded', this.handleAnnotationAdded);
    }

    switchOut() {
      this.off('annotationAdded', this.handleAnnotationAdded);
    }

    mouseLeftUp(evt) {
      if (!this.annotation) {
        return;
      }

      // values are in page coordinates with (0, 0) in the top left
      //   this.annotation.Width = 200;
      //   this.annotation.Height = 25;
      this.annotation.FillColor = new Annotations.Color('#FFFFFF');
      this.annotation.StrokeColor = new Annotations.Color('#CDCDCD');
      this.annotation.Author = annotManager.getCurrentUser();

      // need to draw the annotation otherwise it won't show up until the page is refreshed
      // annotManager.addAnnotation(this.annotation);
      // annotManager.redrawAnnotation(this.annotation);
      super.mouseLeftUp(evt);
      instance.setToolMode('AnnotationEdit');
    }


    mouseLeftDown(evt) {
      super.mouseLeftDown(evt);
    }
  }

  // register the custom tool
  const toolObject = new WhiteOutTool(docViewer, WhiteOutAnnotation);

  instance.registerTool({
    toolName: 'WhiteOutTool',
    buttonImage: whiteOutToolIcon,
    buttonName: 'WhiteOutTool',
    tooltip: 'White Out',
    toolObject,
    showColor: 'never',
  }, WhiteOutAnnotation);

  // add tool to toolClasses map
  toolClasses.WhiteOutTool = WhiteOutTool;
  instance.Tools.WhiteOutTool = WhiteOutTool;
  // add annot to annotClasses map
  tools.WhiteOutTool = {
    type: 'toolButton',
    toolName: 'WhiteOutTool',
    dataElement: 'whiteout-tool',
  };


  // return modified args

  return {
    instance,
    annotClasses,
    toolClasses,
    tools,
    ...rest,
  };
};


const registerButton = injectTool('WhiteOutTool', {
  type: 'toolButton',
  toolName: 'WhiteOutTool',
  title: 'White Out',
  dataElement: 'whiteout',
});


const registerWhiteOut = R.pipeP(
  defineWhiteoutAnnot,
  defineWhiteoutTool,
  registerButton
);

export default registerWhiteOut;