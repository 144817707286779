import _ from 'lodash';
import * as R from 'ramda';

export async function signatureResetOnOtherDocs(userId, docIdToIgnore, server, type) {
  /*
    const allModifiedXfdfs = await server.getModifiedXfdf();
    const otherModifiedXfdfs = Object.entries(allModifiedXfdfs || {}).filter(([docId]) => docId !== docIdToIgnore);
    This function previously used the above logic -- but getModifiedXfdf() is NOT working
    TODO : Remove/FIX getModifiedXfdf()
  */

  // We also have to delete annotations in other documents if they've been modified!
  const docIds = await server.getLoadedDocs();

  let otherDocsAnnots = [];
  await Promise.all(docIds.filter(docId => docId !== docIdToIgnore).map( async (docId, i) => {
    const annots = await server.getAnnotations(docId);
    Object.keys(annots).map(a => otherDocsAnnots.push(annots[a]))
  }));

  const ids = await Promise.all(otherDocsAnnots.map(async ({docId, xfdf}) => {
    // Start getting the annotations but concurrently parse the XML, and then wait on them to load up
    const annotsP = server.getAnnotations(docId);
    const doc = new DOMParser().parseFromString(xfdf, 'application/xml');
    const annots = await annotsP;

    const ids = await Promise.all(Array.from(doc.querySelectorAll('ink,stamp')).map(async (xmlNode) => {
      const annotId = xmlNode.getAttribute('name');
      const trn = xmlNode.querySelector('trn-custom-data');
      const customData = JSON.parse(trn?.getAttribute('bytes') || 'null');
      const corrId = customData?.corrId;

      if (customData?.type !== 'initials' && customData?.type !== 'signature') {
        return;
      }

      const placedAnnot = _.find(annots, (annot) => {
        if (corrId) {
          return annot.corrId === corrId && annot.authorId === userId && customData?.type === type;
        }

        return annot.authorId === userId && annot.id === annotId && customData?.type === type;
      });

      if (!placedAnnot) {
        return;
      }

      // Ok, so we have an xmlNode node which belongs to this author, and it's a signature/initial annotation.
      // We have to remove it, but also update the annotationsRef in firebase
      xmlNode.parentNode.removeChild(xmlNode);

      // doc.removeChild(xml)
      if (corrId) {
        await server.updateAnnotation(corrId, { ...annots[corrId], hidden: false });
      }

      // Make the tag unhidden
      // Remove the signature/initial annotation
      await server.deleteAnnotation(placedAnnot.id);

      return placedAnnot.id;
    }));

    // Save the modified xfdf
    await Promise.all([
      server.saveModifiedXfdf(docId, xfdf),
    ]);

    return ids;
  }));

  return R.pipe(R.unnest(), R.reject(R.isNil()))(ids);
}
export default signatureResetOnOtherDocs;
