import React, { Component } from 'react';
import _ from 'lodash';
import * as R from 'ramda';
import {
  setDateFormat,
} from '../setters';
import annotNameIncludes from '../../helpers/annotNameIncludes';

const getTemplateAnnots = R.filter(
  (annot) => annotNameIncludes(annot, 'DateTemplate')
);


export default class SetDateFormat extends Component {
  constructor(...args) {
    super(...args);
    this.state = { dateFormat: { value: 'MM/DD/YYYY', display: 'MM/DD/YYYY' } };
    this.formatOptions = [
      { value: 'MM/DD/YYYY', display: 'MM/DD/YYYY' },
      { value: 'DD/MM/YYYY', display: 'DD/MM/YYYY' },
      { value: 'MM/DD/YY', display: 'MM/DD/YY' },
      { value: 'DD/MM/YY', display: 'DD/MM/YY' },
      { value: 'MMMM do, yyyy', display: 'Full Date' },
    ];
  }

  componentDidMount = async () => {
    const selectedAnnots = this.props.annotManager.getSelectedAnnotations();

    const annots = _.filter(selectedAnnots, (a) => annotNameIncludes(a, 'Template'));

    if (annots) {
      const dateFormat = _.chain(annots)
        .head()
        .get('CustomData.flags.dateFormat')
        .value();

      await this.setState({ dateFormat });
    }
  }

  render() {
    const selectedAnnots = this.props.annotManager.getSelectedAnnotations();
    const annots = getTemplateAnnots(selectedAnnots);

    // TODO: set annot.Author and annot.CustomData.type
    if (annots.length === selectedAnnots.length) {
      return (
        <div style={{ paddingRight: 5 }}>
          <label htmlFor='signer'>Date Format: </label>
          <select
            value={this.state.dateFormat}
            onChange={(ev) => {
              _.map(annots, (a) => {
                if (_.isNil(a.setDateFormat)) {
                  setDateFormat(a, ev.target.value, this.props.instance);
                } else {
                  a.setDateFormat(ev.target.value);
                }

                this.setState({ dateFormat: ev.target.value });
              });
            }}
          >
            {
              _.map(this.formatOptions, (format, index) => (
                <option
                  key={index}
                  value={format.value}
                >
                  {format.display}
                </option>
              ))
            }
          </select>
        </div>
      );
    }

    return null;
  }
}
