import _ from 'lodash';

const enableContextMenuItems = async ({ instance, config = {}, tools, ...rest }) => {
  const currItems = instance.contextMenuPopup.getItems();
  const { contextMenuItems = [] } = config;


  const newCtxMenuItems = _.filter(currItems, (c) => !!_.find(contextMenuItems, (el) => _.isEqual(c.dataElement, el)));

  instance.contextMenuPopup.update(newCtxMenuItems);


  return {
    instance,
    config,
    tools,
    ...rest,
  };
};

export default enableContextMenuItems;
