import _ from 'lodash';
import { validate } from 'uuid';
import handleSignature from './handleSignature';

const removeAllUserSignatures = ({ instance }) => async (signature) => {
  if (!signature) {
    return;
  }

  const isNotary = () => instance.annotManager.getIsAdminUser();

  const author = signature.Author;
  const currentUserId = instance.annotManager.getCurrentUser();

  if (isNotary()) {
    return;
  }

  if (author !== currentUserId) {
    return;
  }

  const annotations = [...instance.annotManager.getAnnotationsList()];
  const isSignature = (annot) =>
    annot.Subject === 'Signature' &&
    annot.Author === author &&
    annot.CustomData.type === signature.CustomData.type;
  const annotsToDelete = _.filter(annotations, isSignature);

  await Promise.all([
    instance.annotManager.deleteAnnotations(annotsToDelete),
  ]);
  instance.docViewer.trigger(
    'deleteOtherDocSignatureItems',
    currentUserId,
    instance.getDocId(),
    signature.CustomData.type
  );
};

const signatureReset = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const nsId = searchParams.get('nsId');

  return ({ instance, ...rest }) => {
    const handleReset = removeAllUserSignatures({ instance, ...rest });
    const sigTool = instance.docViewer.getTool('AnnotationCreateSignature');

    const createHandleSigInitialsDeletedOrSaved =
      (deletedOrSaved = 'Saved') =>
        async (annots) => {
          const annot = _.head(_.castArray(annots));

          if (
            annot &&
            annot.CustomData &&
            (annot.CustomData.type === 'signature' ||
              annot.CustomData.type === 'initials')
          ) {
            if (annot instanceof instance.Annotations.FreeHandAnnotation) {
              const paths = annot.getPaths();
              if (paths.length === 0) {
                return;
              }
            } else {
              if (_.isEmpty(annot.ImageData)) {
                return;
              }
            }

            const signerId = annot.CustomData.signerId || annot.Author;
            const sigType = annot.CustomData.type;
            if (validate(signerId)) {
              const sigTool = instance.docViewer.getTool(
                'AnnotationCreateSignature'
              );

              if (annot.Color) {
                sigTool.setStyles((currentStyle) => ({
                  StrokeColor: annot.Color,
                }));
              }

              const imageData = await sigTool.getPreview(annot);

              handleSignature({
                action:
                  sigType === 'signature'
                    ? `signature${deletedOrSaved}`
                    : `initials${deletedOrSaved}`,
                payload: {
                  type: sigType,
                  nsUserId: signerId,
                  nsId,
                  image: imageData,
                },
              });
            }
          }
        };

    const signatureDeleted = createHandleSigInitialsDeletedOrSaved('Deleted');
    const signatureSaved = createHandleSigInitialsDeletedOrSaved('Saved');

    sigTool.on('signatureDeleted', async (annots) => {
      await signatureDeleted(annots);
      return handleReset(annots);
    });

    sigTool.on('signatureSaved', signatureSaved);
  };
};

export default signatureReset;
