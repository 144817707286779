import createTemplateTool from './createTemplateTool';
import { disclaimerIcon, notaryVerbiageTitleToolIcon, documentId, commissionId, commissionExpiration, notarySessionId, notaryCountyIcon, stateIcon } from '../../constants/icons';
import { hotKeys } from '../../constants/hotKeys';
import { notaryToolsGroup } from '../../constants/toolGroups';


const createNotaryVerbiageDocIdTemplateTool = createTemplateTool('DocumentId', documentId, hotKeys.R, notaryToolsGroup);
const createNotaryVerbiageCommissionIdTemplateTool = createTemplateTool('CommissionId', commissionId, hotKeys.Q, notaryToolsGroup);
const createNotaryVerbiageCommissionExpirationTemplateTool = createTemplateTool('CommissionExpiration', commissionExpiration, hotKeys.E, notaryToolsGroup);
const createNotaryVerbiageNotarySessionIdTemplateTool = createTemplateTool('NotarySessionId', notarySessionId, hotKeys.S, notaryToolsGroup);
const createNotaryVerbiageDisclaimerTemplateTool = createTemplateTool('Disclaimer', disclaimerIcon, hotKeys.W, notaryToolsGroup);
const createNotaryVerbiageTitleTemplateTool = createTemplateTool('NotaryTitle', notaryVerbiageTitleToolIcon, hotKeys.T, notaryToolsGroup);
const createNotaryCountyTemplateTool = createTemplateTool('NotaryCounty', notaryCountyIcon, hotKeys.L, notaryToolsGroup);
const createNotaryStateTemplateTool = createTemplateTool('NotaryState', stateIcon, hotKeys.X, notaryToolsGroup);

export {
  createNotaryVerbiageDocIdTemplateTool,
  createNotaryVerbiageCommissionIdTemplateTool,
  createNotaryVerbiageCommissionExpirationTemplateTool,
  createNotaryVerbiageNotarySessionIdTemplateTool,
  createNotaryVerbiageDisclaimerTemplateTool,
  createNotaryVerbiageTitleTemplateTool,
  createNotaryCountyTemplateTool,
  createNotaryStateTemplateTool,
};
