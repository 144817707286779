import _ from 'lodash';

export const injectToolArr = async ({ instance, config }) => ({
  instance,
  config,
  annotClasses: {},
  tools: {
    Spacer: { type: 'spacer' },
    Divider: { type: 'divider' },
  },
  toolClasses: {},
  panels: {},
});

export const injectTool = (toolName, toolButtonConfig) => async ({ tools, ...rest }) => {
  let val;

  if (_.isFunction(toolButtonConfig)) {
    val = toolButtonConfig({ tools, ...rest });
  } else if (toolButtonConfig.onClick) {
    val = {
      ...toolButtonConfig,
      onClick: toolButtonConfig.onClick({ ...rest, tools }),
    };
  } else {
    val = toolButtonConfig;
  }

  return {
    ...rest,
    tools: {
      ...tools,
      [toolName]: val,
    },
  };
};


export const injectPanel = (panelName, panelConfig) => async ({ panels, ...rest }) => ({
  ...rest,
  panels: {
    ...panels,
    [panelName]: _.isFunction(panelConfig) ? await panelConfig({ panels, ...rest }) : panelConfig,
  },
});
