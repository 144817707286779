export const hotKeys = {
  F: 'f',
  ESCAPE: 'escape',
  V: 'v',
  R: 'r',
  B: 'b',
  N: 'n',
  Q: 'q',
  W: 'w',
  E: 'e',
  T: 't',
  S: 's',
  C: 'c',
  A: 'a',
  Z: 'z',
  X: 'x',
  O: 'o',
  L: 'l',
  Y: 'y',
  5: '5',
};
