import _ from 'lodash';
import * as R from 'ramda';
import { injectTool } from '../initializers/injectors';

import registerHeaderGroup from '../initializers/registerHeaderGroup';
import defineAnnotClass from '../initializers/defineAnnotClass';
import defineToolClass from '../initializers/defineToolClass';
import registerAnnotationType from '../initializers/registerAnnotationType';
import registerTool from '../initializers/registerTool';
import { uploadIcon, certToolIcon, defaultCertIcon } from '../constants/icons';


const onMouseLeftUp = () => async ({ context, instance }) => {
  let annotation;
  const inst = context.tool;

  instance.Tools.GenericAnnotationCreateTool.prototype.mouseLeftDown.call(inst, context.args[0]);

  if (inst.annotation) {
    const { dataUrl, img } = await instance.getCertPdf();

    if (!dataUrl) {
      return;
    }

    inst.aspectRatio = img.width / img.height;
    let width = 300;

    let height = width / inst.aspectRatio;

    const rotation = instance.docViewer.getCompleteRotation(1) * 90;

    inst.annotation.Rotation = rotation;

    if (rotation === 270 || rotation === 90) {
      const t = height;

      height = width;
      width = t;
    }

    inst.annotation.ImageData = dataUrl;
    inst.annotation.Width = width;
    inst.annotation.Height = height;
    inst.annotation.X -= width / 2;
    inst.annotation.Y -= height / 2;

    annotation = inst.annotation;
  }

  instance.Tools.GenericAnnotationCreateTool.prototype.mouseLeftUp.call(inst, context.args[0]);

  if (annotation) {
    const annotManager = instance.docViewer.getAnnotationManager();

    annotManager.deselectAllAnnotations();
    annotManager.redrawAnnotation(annotation);
    annotManager.selectAnnotation(annotation);
    instance.setToolMode('AnnotationEdit');
  }
};


const onMouseLeftDown = () => async ({ context, instance }) => {
  const { args } = context;
  const am = instance.docViewer.getAnnotationManager();
  const annot = am.getAnnotationByMouseEvent(context.args[0]);

  if (annot) {
    return;
  }

  instance.Tools.AnnotationSelectTool.prototype.mouseLeftDown.apply(context.tool, [args[0]]);
};


const createInjectCertTool = ({ pdfUrl, icon, type, toolName, tooltip }) => injectTool(toolName, {
  type: 'actionButton',
  img: icon || defaultCertIcon,
  dataElement: _.camelCase(toolName),
  onClick: ({ instance }) => async () => {
    await instance.docViewer.trigger('certModal', { type, pdf: pdfUrl });

    return instance.setActiveHeaderGroup('default');
  },
  title: tooltip,
  // hidden: ['tablet', 'mobile']
});


const createStampAnnotTool = R.pipeP(
  defineAnnotClass({
    className: 'NotaryCertAnnotation',
    baseClassName: 'StampAnnotation',
  }),

  // define tool class that uses annot class
  defineToolClass({
    className: 'NotaryCertTool',
    annotClassName: 'NotaryCertAnnotation',
    baseClassName: 'GenericAnnotationCreateTool',
    switchIn: async ({ instance }) => instance.setActiveHeaderGroup('default'),
    onMouseLeftDown: onMouseLeftDown(),
    onMouseLeftUp: onMouseLeftUp(),
  }),

  registerTool({
    annotClassName: 'NotaryCertAnnotation',
    toolName: 'NotaryCertTool',
    // buttonImage,
    buttonName: 'NotaryCertTool',
  }),


  // register the custom annot class to webviewer
  registerAnnotationType({
    elementName: 'cert',
    annotClassName: 'NotaryCertAnnotation',
  }),

  createInjectCertTool({
    toolName: 'AcknowledgementCertTool',
    type: 'acknowledgement',
    tooltip: 'Acknowledgement Cert',
    pdfUrl: '/static/pdf/certs/acknowledgement.pdf'
  }),
  createInjectCertTool({
    toolName: 'AcknowledgementCertWithMarkTool',
    type: 'acknowledgementWithMark',
    tooltip: 'Acknowledgement Cert (w/ mark)',
    pdfUrl: '/static/pdf/certs/acknowledgement_with_mark.pdf'
  }),
  createInjectCertTool({
    toolName: 'AcknowledgementCertWithSigAffixedTool',
    type: 'acknowledgementWithSigAffixed',
    tooltip: 'Acknowledgement Cert (w/ sig affixed)',
    pdfUrl: '/static/pdf/certs/acknowledgement_sig_affixed.pdf'
  }),

  createInjectCertTool({
    toolName: 'JuratCertTool',
    type: 'jurat',
    tooltip: 'Jurat Cert',
    pdfUrl: '/static/pdf/certs/jurat.pdf'
  }),
  createInjectCertTool({
    toolName: 'JuratCertWithMarkTool',
    type: 'jurat',
    tooltip: 'Jurat Cert (w/ mark)',
    pdfUrl: '/static/pdf/certs/jurat_with_mark.pdf'
  }),
  createInjectCertTool({
    toolName: 'JuratCertPhysicallyUnableToSignTool',
    type: 'jurat',
    tooltip: 'Jurat Cert (physically unable to sign)',
    pdfUrl: '/static/pdf/certs/jurat_physically_unable_to_sign.pdf'
  }),

  createInjectCertTool({
    toolName: 'CertificationOfCopyByUsPassportHolder',
    type: 'certificationOfCopyByUSPassportHolder',
    tooltip: 'Certification Of Copy by US Passport Holder',
    pdfUrl: '/static/pdf/certs/certification_of_copy_by_us_passport_holder.pdf'
  }),
  createInjectCertTool({
    toolName: 'AffidavitOfTranslator',
    type: 'affidavitOfTranslator',
    tooltip: 'Affidavit Of Translator',
    pdfUrl: 'https://storage.googleapis.com/enl-static-files/certs/affidavit_of_translator.pdf',
  }),
  createInjectCertTool({
    toolName: 'CertFile',
    type: 'file',
    icon: uploadIcon,
    tooltip: 'Upload PDF',
  }),

  injectTool('CertTool', {
    type: 'actionButton',
    img: certToolIcon,
    title: 'Cert Tools',
    dataElement: 'certTools',
    onClick: ({ instance }) => () => instance.setActiveHeaderGroup('certToolsGroup'),
  }),

  registerHeaderGroup({
    groupName: 'certToolsGroup',
    toolNames: [
      'AcknowledgementCertTool',
      'AcknowledgementCertWithMarkTool',
      'AcknowledgementCertWithSigAffixedTool',
      'JuratCertTool',
      'JuratCertWithMarkTool',
      'JuratCertPhysicallyUnableToSignTool',
      'CertificationOfCopyByUsPassportHolder',
      'CertFile',
    ],
  })
);

export default createStampAnnotTool;
