import Promise from 'bluebird';
import _ from 'lodash';


export const applyFormFieldCreate = () => async ({ instance }) => {
  const { Annotations } = instance;
  const annotManager = instance.docViewer.getAnnotationManager();

  Annotations.WidgetAnnotation.getContainerCustomStyles = function (widget) {
    if (widget) {
      return {
        border: '1px dashed #ff0000',
        backgroundColor: 'rgba(50, 115, 220, 0.3)',
      };
    }
  };

  Annotations.WidgetAnnotation.getCustomStyles = function (widget) {
    if (widget instanceof Annotations.TextWidgetAnnotation) {
      return { 'background-color': 'rgba(50, 115, 220, 0.3)' };
    }

    if (widget) {
      return {
        border: '0px solid #000',
      };
    }
  };

  const customTypes = [
    'SIGNATURE',
    'INITIALS',
    'CHECKBOX',
    'FORM',
    'CHECK',
    'TEXT',
  ];

  const annotations = [...annotManager.getAnnotationsList()];

  const fieldManager = instance.annotManager.getFieldManager();

  const isFreeText = (annot) => annot.Subject !== 'Widget' && annot instanceof Annotations.FreeTextAnnotation && (!_.isEmpty(annot.custom) || !_.isEmpty(annot.CustomData));

  const annotationsList = _.filter(annotations, isFreeText);

  const { toDelete: annotsToDelete, toDraw } = await Promise.reduce(annotationsList, async (acc, annot) => {
    let field;
    let inputAnnot;

    // if existing annotation has a `custom` property then use its value to convert to our custom annotation
    if (annot.custom) {
      if (annot instanceof Annotations.FreeTextAnnotation && customTypes.indexOf(annot.custom.type) !== -1) {
        // create flag
        const flags = new Annotations.WidgetFlags();

        if (annot.custom.flags.readOnly) {
          flags.set('ReadOnly', true);
        }

        if (annot.custom.flags.multiline) {
          flags.set('Multiline', true);
        }

        if (annot.custom.flags.required) {
          flags.set('Required', true);
        }
        
        if (annot.custom.flags.edit) {
          flags.set('Edit', annot.custom.flags.edit);
        }

        if (annot.custom.flags.dateFormat) {
          flags.set('DateFormat', annot.custom.flags.dateFormat);
        }

        if (annot.custom.flags.yearFormat) {
          flags.set('YearFormat', annot.custom.flags.yearFormat);
        }

        if (annot.custom.flags.stateFormat) {
          flags.set('StateFormat', annot.custom.flags.stateFormat);
        }

        if (annot.custom.flags.addressFormat) {
          flags.set('AddressFormat', annot.custom.flags.addressFormat);
        }


        const runId = instance.getRunId();

        // create a form field based on the type of annotation
        // ref: https://www.pdftron.com/webviewer/demo/pdf-form-build
        // ref: https://www.pdftron.com/documentation/web/guides/forms/create-checkbox-field/
        if (annot.custom.type === 'TEXT' || annot.custom.type === 'FORM') {
          const identifier = `form.${runId}.${annot.custom.id}.${annot.custom.author}.${annot.custom.signerId}.${annot.custom.name}`;

          field = new Annotations.Forms.Field(identifier, { type: 'Tx', value: annot.custom.value, flags });
          inputAnnot = new Annotations.TextWidgetAnnotation(field);
        } else if (annot.custom.type === 'SIGNATURE' || annot.custom.type === 'INITIALS') {
          const type = (annot.custom.type === 'INITIALS') ? 'initials' : 'signature';
          const identifier = `${type}.${runId}.${annot.custom.id}.${annot.custom.author}.${annot.custom.signerId}.${annot.custom.name}`;

          field = new Annotations.Forms.Field(identifier, { type: 'Sig', value: annot.custom.value, flags });


          inputAnnot = new Annotations.BetterSigWidgetAnnotation(field, {
            appearance: '_DEFAULT',
            appearances: {
              _DEFAULT: {
                // eslint-disable-next-line max-len
                Normal: { data: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC', offset: { x: 100, y: 100 } },
              },
            },
          });
        } else if (annot.custom.type === 'CHECKBOX' || annot.custom.type === 'CHECK') {
          const identifier = `checkbox.${runId}.${annot.custom.id}.${annot.custom.author}.${annot.custom.signerId}.${annot.custom.name}`;

          const font = new Annotations.Font({ name: 'Helvetica' });

          field = new Annotations.Forms.Field(identifier, {
            type: 'Btn',
            value: 'Off',
            flags,
            font,
          });

          inputAnnot = new Annotations.CheckButtonWidgetAnnotation(field, {
            appearance: 'Off',
            appearances: { Off: {}, Yes: {} },
          });
        } else {
          // exit early for other annotations
          return {
            ...acc,
          };
        }


        inputAnnot.PageNumber = annot.getPageNumber();
        inputAnnot.X = annot.getX();
        inputAnnot.Y = annot.getY();
        inputAnnot.rotation = annot.Rotation;

        if (annot.Rotation === 0 || annot.Rotation === 180) {
          inputAnnot.Width = annot.getWidth();
          inputAnnot.Height = annot.getHeight();
        } else {
          inputAnnot.Width = annot.getHeight();
          inputAnnot.Height = annot.getWidth();
        }

        inputAnnot.custom = inputAnnot.CustomData = { ...annot.custom, ...annot.CustomData, id: annot.custom.id };
        inputAnnot.Author = annot.custom.name;
        Annotations.WidgetAnnotation.getContainerCustomStyles(inputAnnot);
        Annotations.WidgetAnnotation.getCustomStyles(inputAnnot);

        annotManager.addAnnotation(inputAnnot, false);
        fieldManager.addField(field);

        return {
          toDraw: [...acc.toDraw, inputAnnot],
          toDelete: [...acc.toDelete, annot],
        };
      }
    }

    return acc;
  }, {
    toDelete: [],
    toDraw: [],
  });

  // await annotManager.addAnnotations(toAdd, false);
  await Promise.all([
    annotManager.deleteAnnotations(annotsToDelete, true, false, false),
    annotManager.drawAnnotationsFromList(toDraw),
  ]);


  // set tool to AnnotationEdit
  await instance.setToolMode('AnnotationEdit');

  return instance.setActiveHeaderGroup('default');
};

export default applyFormFieldCreate;
