import React from 'react';

import * as R from 'ramda';
import { injectTool } from '../initializers/injectors';


export class LockButton extends React.Component {
  constructor(props, ctx) {
    super(props, ctx);

    this.state = { locked: false };
  }

  componentDidMount() {
    const { instance } = this.props;

    this.setState({ locked: instance.getLock() });


    instance.docViewer.on('lockChanged', (locked) => this.setState({ locked }));
  }

  render() {
    return (
      <div className='lock-button-grid'>
        <label style={{margin:'auto'}}>Signer Page Lock</label>
        <input
          type='checkbox'
          checked={this.state.locked}
          onChange={(evt) => this.props.instance.docViewer.trigger('setLockStatus', [evt.target.checked])}
          style={{margin:'auto'}}
        />
      </div>
    );
  }
}


const showLockButton = ({ instance }) => ({
  type: 'customElement',
  title: 'Signer Page Lock Checkbox',
  dataElement: 'lockCheckbox',
  render: () => <LockButton instance={instance} />,
});

const registerShowSigner = R.pipeP(
  injectTool('LockCheckbox', showLockButton)
);

export default registerShowSigner;
