import _ from 'lodash';

const disableHotkeys = async ({ instance, config = {}, tools, ...rest }) => {
  _.map(config.hotKeys, (hotKey) => instance.hotkeys.off(hotKey));

  return {
    instance,
    config,
    tools,
    ...rest,
  };
};

export default disableHotkeys;
