import { createContext, ReactNode, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { roomApi } from '../clients/roomApi';
import { consumerRoomApi } from '../clients/consumerRoomApi';
import { ConsumerApi, NotaryApi } from '../types';
import { pick } from 'ramda';
import { mapProps } from 'recompose';
import { useInterval, useLocation } from 'react-use';
import debug from 'debug';
import { useParams } from './useParams';

const log = debug('hooks:useApi');

const ApiContext = createContext<NotaryApi | ConsumerApi>({} as NotaryApi);

export function useApi<T extends NotaryApi | ConsumerApi = NotaryApi>() {
  return useContext(ApiContext) as T;
}


interface IApiProviderProps {
  token?: string;
  type: string;
  children: ReactNode;
}

export function ApiProviderBase({ token, type, children }: IApiProviderProps) {
  const params = useParams<{ type?: string }>();
  const location = useLocation();



  const client = useMemo(() => {
    if ((params.type && params.type === 'consumer') || (location.pathname || '').includes('/notarization/rooms')) {
      return consumerRoomApi();
    }
    if (type === 'notary') {
      if (!token) {
        throw new Error('Failed to initialize room api. No token was supplied');
      }
      return roomApi(token)
    }
    return consumerRoomApi();
  }, [params.type, token, type, location.pathname]);


  // every two minutes update access token
  useInterval(async () => {
    log('refreshing access token');
    if (type === 'notary' && (client as any).getUser) {
      const user = await (client as NotaryApi).getUser();
      (client as NotaryApi).setToken(user.token);
      window.parent.postMessage({ status: 'update-token', user }, '*')
    }
  }, 60000 * 2);

  return (
    <ApiContext.Provider value={client}>
      {children}
    </ApiContext.Provider>
  );
}

ApiProviderBase.propTypes = {
  token: PropTypes.string,
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const composeComponent = mapProps(pick(['token', 'type', 'children']));

export const ApiProvider = composeComponent(ApiProviderBase);
