/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import _ from 'lodash';
import * as R from 'ramda';
import annotNameIncludes from '../../helpers/annotNameIncludes';
import {
  setIsRequired,
} from '../setters';

import debug from 'debug';

const log = debug('vanilla:lib');

const getTemplateAnnots = R.filter(
  (annot) => annotNameIncludes(annot, 'Template') && annot?.Subject && !annot?.Subject.includes('CheckHere') && !annot?.Subject.includes('RadioButton')
);

export default class RequiredCheckbox extends Component {
  constructor(...args) {
    super(...args);
    this.state = { checked: false };
  }

  componentDidMount = async () => {
    const instanceCustomData = JSON.parse(this.props.instance.getCustomData());
    const selectedAnnots = this.props.annotManager.getSelectedAnnotations();


    const checked = _.reduce(selectedAnnots, (acc, annot) => acc && _.get(annot, 'CustomData.flags.required', false), true);
    const isMixed = _.reduce(selectedAnnots, (acc, annot) => acc || _.get(annot, 'CustomData.flags.required', false), false);
    const isAdmin = this.props.instance.annotManager.getIsAdminUser();
    _.map(selectedAnnots, (annot) => {
      if (instanceCustomData.taggingMode === false && !isAdmin) {
        if (annotNameIncludes(annot, 'Template')) {
          log('locing contents');
          annot.Locked = isMixed;
        }

        annot.NoMove = isMixed;
        annot.ContentLocked = isMixed;
      }
    });
    await this.setState({ checked, isMixed });
  }

  handleChange = (annots) => (evt) => {
    this.setState({ checked: evt.target.checked });

    _.map(annots, (annot) => {
      if (_.isNil(annot.setAddressFormat)) {
        setIsRequired(evt.target.checked, annot, this.props.instance);
      } else {
        annot.setIsRequired(evt.target.checked);
      }
    });
  }

  render() {
    const instanceCustomData = JSON.parse(this.props.instance.getCustomData());
    const selectedAnnots = this.props.annotManager.getSelectedAnnotations();
    const annots = getTemplateAnnots(selectedAnnots);

    // Previously, this also checked && instanceCustomData.taggingMode === true
    // Check removed for V2-3034 to make the Required checkbox available
    if (annots.length === selectedAnnots.length) {
      return (
        <div style={{ paddingRight: 5 }}>
          <label
            className='form-check-label'
            htmlFor='isRequired'
          >
            Required
          </label>
          <input
            type='checkbox'
            className='form-check-input'
            id='isRequired'
            checked={this.state.checked}
            onChange={this.handleChange(annots)}
          />
        </div>

      );
    }

    return <></>;
  }
}
