import { filter, isNil, map, difference} from "lodash";

export const checkIfAllRequiredTags = (tagsList: any) => {
    const requiredTags = filter(tagsList, (annot) => annot.CustomData?.flags?.required && isNil(annot.CustomData?.corrId));
    const appliedTags = filter(tagsList, (annot) => !isNil(annot.CustomData?.corrId));
    const requiredTagIds = map(requiredTags, (a) => a.Id);
    const appliedTagCorrIds = map(appliedTags, (a) => a.CustomData.corrId);
    const unappliedRequiredTags = difference(requiredTagIds, appliedTagCorrIds);
    return unappliedRequiredTags.length === 0;
};

export const checkIfAllSignerRequiredTags = (tagsList: any) => {
    const requiredTags = filter(tagsList, (annot) => annot.CustomData?.flags?.required && isNil(annot.CustomData?.corrId) && !annot.CustomData.name?.includes('Notary'));
    const appliedTags = filter(tagsList, (annot) => !isNil(annot.CustomData?.corrId) && !annot.CustomData.name?.includes('Notary'));
    const requiredTagIds = map(requiredTags, (a) => a.Id);
    const appliedTagCorrIds = map(appliedTags, (a) => a.CustomData.corrId);
    const unappliedRequiredTags = difference(requiredTagIds, appliedTagCorrIds);
    return unappliedRequiredTags.length === 0;
};

export const checkIfAnySignerRequiredTags = (tagsList: any) => {
    const requiredTags = filter(tagsList, (annot) => annot.CustomData?.flags?.required && isNil(annot.CustomData?.corrId) && !annot.CustomData.name?.includes('Notary'));
    return requiredTags.length > 0;
};

export { filter, isNil, map, difference };
