export default {
  panelNames: [],
  toolNames: ['NotaryTools', 'NameTools', 'Divider', 'WhiteOutTool', 'StrikeOutTool'],
  popupNames: [
    'AssignSigner',
    'SetDateFormat',
    'SetYearFormat',
    'SetStateFormat',
    'SetAddressFormat',
    'SetRequired',
  ],
  hotKeys: [],
};
