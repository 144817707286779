/* eslint-disable no-param-reassign */
import { WebViewerInstance, Annotations } from '@pdftron/webviewer';
import { createFreeTextAnnot, ICreateAnnotationOptions } from '../createAnnotation';


// Use this function for the sizing and actual creation of the annotation
export const createNameFreeText = async (
  instance: WebViewerInstance,
  name: string,
  annot: Annotations.FreeTextAnnotation,
  options: ICreateAnnotationOptions | Record<string, any> = {}
) => {

  // NOTE: if name doesn't exist just hide template
  if (name) {
    return createFreeTextAnnot(instance, name, annot, options);
  }

  console.warn('name.createAnnotation: name was empty')
  return null;
};



export const createFirstNameAnnotation = createNameFreeText;
export const createFullNameAnnotation = createNameFreeText;
export const createLastNameAnnotation = createNameFreeText;
export const createMiddleNameAnnotation = createNameFreeText;
export const handleCreateName = createNameFreeText;