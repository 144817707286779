/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import _ from 'lodash';
import * as R from 'ramda';

import { createContent, parseField, tagTypes, parseName } from '@enotarylog/tag-utils';

import annotNameIncludes from '../../helpers/annotNameIncludes';
import { loadImgWithDataUrl, getSvgDimensions } from '../../helpers/loadImg';


export default class SelectSigner extends Component {
  constructor(...args) {
    super(...args);
    this.state = { signerId: null };
  }

  componentDidMount = async () => {
    const selectedAnnots = this.props.annotManager.getSelectedAnnotations();


    const annots = _.filter(selectedAnnots, (a) => (a.Subject && a.Subject.includes('Template')));

    let hasNotaryTag = _.filter(selectedAnnots, (a) => a.CustomData.type in tagTypes.notaryVerbiageTags || annotNameIncludes(a, 'SealTag') || a.CustomData.type === tagTypes.addressTags.STATETEMPLATE.type);
    const hasSignerTemplate = _.filter(selectedAnnots, (annot) => (annot.CustomData.type in tagTypes.nameTags || annot.CustomData.type in tagTypes.dateTags));


    const notary = _.find(this.props.signers, { type: 'notary' });

    const signerIds = _.map(annots, 'CustomData.signerId');

    if (
      _.uniq(signerIds).length === 1
      &&
      _.findIndex(this.props.signers, { id: _.uniq(signerIds)[0] }) > -1
    )  {
      return this.setState({ signerId: _.uniq(signerIds)[0] });
    }

    if (annots.length === 1) {
      const signerId = _.chain(annots)
        .head()
        .get('CustomData.signerId')
        .value();

      if (R.not(R.isEmpty(hasNotaryTag))) {
        await this.setState({ signerId: `${notary.id}` });
        _.map(annots, (a) => {
          this.setSigner(`${notary.id}`, a);
        });
      } else if (R.not(R.isEmpty(hasSignerTemplate)) && notary.id !== signerId) {
        const signer = _.find(this.props.signers, { id: signerId });
        // Set to head of signers
        if (signer) {
          _.map(annots, (a) => {
            this.setSigner(signer.id, a);
          });
          this.setState({ signerId });
        }
      } else await this.setState({ signerId });
    } else {
      // Set to an hidden option unassigned signer so a signer must be selected
      await this.setState({ signerId: '-1' });
    }
  }

  setSigner = async (id, annot) => {
    const { instance } = this.props;

    const signer = instance.getSignerById(id);
    const fullName = parseName({
      lastName: '',
      ...signer,
    });

    const color = [signer.color.R, signer.color.G, signer.color.B, signer.color.A];
    const customdata = {
      ...annot.CustomData,
      signerId: id,
      color,
      name: fullName,
      fullName,
    };
    const annotName = (annot.Subject || annot.constructor?.name || '').toUpperCase();

    if (annotNameIncludes(annot, 'SealTag')) {
      annot.CustomData = customdata;
      annot.custom = customdata;
      annot.Author = id;

      annot.setModified(true);

      instance.annotManager.drawAnnotationsFromList([...instance.annotManager.getAnnotationsList(), annot]);
      instance.annotManager.trigger('annotationChanged', [[annot], 'modify', { imported: false, isUndoRedo: false }]);

      return this;
    }

    if (annotName in tagTypes.imageTags) {
      const svg = await tagTypes.imageTags[annotName].svg
        .replace('{{color}}', `rgb(${signer.color.R}, ${signer.color.G}, ${signer.color.B}, ${signer.color.A})`)
        .replace('{{SignerName}}', signer?.fullName ? signer?.fullName : fullName);
      const win = window.URL || window.webkitURL || window;
      const blob = new Blob([svg], { type: 'image/svg+xml' });
      const url = win.createObjectURL(blob);
      const { dataUrl } = await loadImgWithDataUrl(url, getSvgDimensions(svg));

      annot.CustomData = customdata;
      annot.custom = customdata;
      annot.Author = id;

      annot.ImageData = dataUrl;
      annot.setModified(true);
      instance.annotManager.redrawAnnotation(annot);
      instance.annotManager.drawAnnotationsFromList([...instance.annotManager.getAnnotationsList(), annot]);
      instance.annotManager.trigger('annotationChanged', [[annot], 'modify', { imported: false, isUndoRedo: false }]);

      return this;
    }


    let content = createContent(annot.CustomData, signer);

    if (R.isNil(content) || R.isEmpty(content)) {
      // Nil or empty give no field entered give no styling
      content = parseField(annot.CustomData.type);
      annot.setRichTextStyle({ 0: {} });
    } else {
      annot.setRichTextStyle({
        0: {
          'text-decoration': 'underline',
        }
      });
    }

    if (R.not(annot.CustomData.type in tagTypes.dateTags)) {
      annot.setContents(content);
    }

    annot.FillColor = new instance.Annotations.Color(...color);
    annot.CustomData = customdata;
    annot.custom = customdata;
    annot.Author = id;

    annot.setModified(true);
    instance.annotManager.redrawAnnotation(annot);
    instance.annotManager.drawAnnotationsFromList([...instance.annotManager.getAnnotationsList(), annot]);
    instance.annotManager.trigger('annotationChanged', [[annot], 'modify', { imported: false, isUndoRedo: false }]);
    return this;
  }

  render() {
    const selectedAnnots = this.props.annotManager.getSelectedAnnotations();

    if(selectedAnnots[0].SY === 'Notary firstname Notary middlename Notary lastname') {
      selectedAnnots[0].SY = 'Notary'
    }

    const templateAnnots = _.filter(selectedAnnots, (a) => (a.Subject && a.Subject.includes('Template')) || _.forEach(tagTypes.imageTags, (type) => {
      annotNameIncludes(a, type);
    }));

    // NOTE: Stop signer select from showing on notary tags and stop showing up on freetext PERIOD
    const annots = _.filter(templateAnnots, (annot) => R.not(annot.CustomData.type in tagTypes.notaryVerbiageTags)
      && R.not(annotNameIncludes(annot, 'SealTag'))
      && annot.CustomData.type !== tagTypes.addressTags.STATETEMPLATE.type
      && annot.Subject !== 'Free text');
    const consumer = _.find(this.props.signers, { type: 'consumer' });
    const otherSigners = _.chain(this.props.signers)
      .filter((el) => !_.isEqual(el.type, 'consumer'))
      .sortBy(['lastName', 'firstName'])
      .value();

    let signers = _.filter([consumer, ...otherSigners], (el) => !_.isNil(el));

    // Don't show this if it already has a signature
    const alreadySigned = _.head(selectedAnnots).Subject === 'Signature';

    // TODO: set annot.Author and annot.CustomData.type
    if (annots.length === selectedAnnots.length && !alreadySigned) {
      return (
        <div>
          <label htmlFor='signer'>Signer: </label>
          <select
            value={this.state.signerId}
            onChange={(ev) => {
              _.map(annots, (a) => {
                this.setSigner(ev.target.value, a);
                this.setState({ signerId: ev.target.value });
              });
            }}
          >
            <option
              key='-1'
              value='-1'
              hidden
              style={{ display: 'none' }}
            >
              Select A Signer
            </option>
            {
              _.map(signers, (signer) => (
                <option
                  key={signer.id}
                  value={signer.id}
                >
                  {signer.type !== 'notary' ? parseName(signer) : 'Notary'}
                </option>
              ))
            }
          </select>
        </div>
      );
    }

    return null;
  }
}
