import { createTheme } from '@material-ui/core';
import { renderRoutes } from '@enotarylog/shared';
import { routes } from './routes';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { FirebaseConfig } from '@enotarylog/shared';
import { ComponentType } from 'react';
import { ToastContainer, toast } from 'react-toastify';

export interface AppProps {
  type: 'notary' | 'signer';
  token?: string;
  config?: FirebaseConfig
}

(window as any).toast = toast;

// This has been added to prevent the ToastContainer to cover up the "Complete" button.
const ToastContainerStyle = {
  bottom: '3em',
};

function App({ type = 'notary', token, config }: AppProps) {
  const theme = createTheme();

  return (
    <>
      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        pauseOnHover={true}
        pauseOnFocusLoss={false}
        theme='colored'
        limit={4}
        icon={false}
        autoClose={10000}
        newestOnTop={false}
        style={ToastContainerStyle}
      />

      {renderRoutes(routes, { token, type, config, theme }, ['token'])}
    </>
  );
}




/**
 * Launch darkly wrapper
 */
const ldWrapper = withLDProvider({
  clientSideID: process.env.NX_LAUNCH_DARKLY_API_KEY,
  user: { anonymous: true },
  options: { allAttributesPrivate: true },
})


export default ldWrapper(App as ComponentType<AppProps>) as ComponentType<AppProps>;
