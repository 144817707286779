/* eslint-disable no-param-reassign */
import debug from 'debug';
import Promise from 'bluebird';
import * as R from 'ramda';
import _ from 'lodash';
import C2S from 'canvas2svg';
import { toggleRequiredArrow, cleanRequiredArrow } from '../helpers/handleRequiredArrows';
import { checkIfIframe } from '@enotarylog/shared';
import { checkIfAllSignerRequiredTags, showToastMessage } from "@enotarylog/shared";

const log = debug('vanilla:lib');

/**
 * imports annotations pushed from firebase for non-widget annotations via importAnnotCommand.
 * If `corrId` present, it will hide the annotation with that id
 */
export const importFbaseVal = (instance, server, api) => async ({ key, val }) => {
  const { selectedDoc, annotManager } = instance;

  const sessionStatus = await server.getStatus();

  log('annotation created/modified');

  if (selectedDoc !== val.docId) {
    log('selectedDoc !== val.docId', {
      selectedDoc,
      annotDocId: val.docId,
      annotId: val.id,
    });

    return;
  }
  log(`importing: ${val.id}: ` + val.xfdf);

  // Import the annotation based on xfdf command
  if (!instance.docViewer.getDocument()) {
    return;
  }

  const annots = await annotManager.importAnnotCommand(val.xfdf);
  const currentUser = annotManager.getCurrentUser();
  const isAdmin = annotManager.getIsAdminUser();

  if (_.includes(val.xfdf, '<delete>')) {
    return;
  }
  // eslint-disable-next-line no-param-reassign

  if (!annots.length) {
    return;
  }

  // Trigger status check to toggle validation status for the document viewer.
  instance.annotManager.trigger('onValidationStatusCheck');

  if (isAdmin) {
    const annotations = instance.annotManager.getAnnotationsList();
    const allRequiredTagsAreSigned = checkIfAllSignerRequiredTags(annotations);

    /*
      If we had some pending required signer tags before but now we don't (either because they were
      executed or because they are no longer required), we show a toast so the notary knows they can
      execute their signature and/or stamp tags.
    */
    if (
      (!instance.getPrevRequiredTagsSignedState() && allRequiredTagsAreSigned) &&
      sessionStatus === 'in_progress'
    ) {
      showToastMessage(window, {
        type: "success",
        message: "Notary signatures and stamps can now be affixed to the current document.",
      });
    }

    /*
      We save the latest "required tags signed" state so we can compare against it the
      allRequiredTagsAreSigned value above to decide when to show the toast.
    */
    instance.setPrevRequiredTagsSignedState(allRequiredTagsAreSigned);
  }

  // Checking if the session was from V1 or V2.
  const isIframe = checkIfIframe();

  await Promise.mapSeries(annots, async (annot) => {
    if (annot.Id !== val.id) {
      return;
    }

    if (isAdmin && annot.Subject === 'Signature' && val.forEnote === true) {
      const canvas = document.createElement('canvas');
      const pageIndex = ((val.pageNumber || 1) - 1);
      const pageMatrix = instance.docViewer.getDocument().getPageMatrix(pageIndex);
      const tagAspectRatio = annot.Width / annot.Height;

      const _annot = instance.annotManager.getAnnotationCopy(annot);

      const cctx = new C2S(canvas.width, canvas.height);

      // Since the annotation will be drawing at (X,Y) , use ‘translate’, so it’s start at 0,0 instead
      cctx.translate(-_annot.X, -_annot.Y);
      _annot.Rotation = _annot.Rotation || 0;
      _annot.draw(cctx, pageMatrix);

      const w = 1222;
      const h = w / tagAspectRatio;
      const scale = h / annot.Height;

      const svg = cctx.getSvg();
      const g = svg.querySelector('g')
      svg.removeChild(g);
      const newG = document.createElement('g')
      newG.setAttribute('transform', `scale(${scale})`);
      newG.append(g);
      svg.append(newG);

      svg.setAttribute('width', w);
      svg.setAttribute('height', h);
      canvas.height = h;
      canvas.width = w;
      const ctx = canvas.getContext('2d');

      const drawIt = await new Promise((res) => {
        const img = new Image();
        img.onload = () => {
          ctx.drawImage(img, 0, 0);
          return res(canvas.toDataURL('image/png'));
        }
        img.src = `data:image/svg+xml;base64,${btoa(svg.outerHTML)}`;
      });

      const base64EncodedImageString = (drawIt).replace(/^data:image\/\w+;base64,/, '');
      const signers = instance.getSigners();
      const signer = _.find(signers, { id: val.authorId });

      if (signer && api && _.isFunction(api.signEnote)) {
        await server.showCompleting('Applying Signature');
        await api.signEnote(signer.notarySessionId, signer.id, {
          image: base64EncodedImageString
        });
        await server.deleteAnnotation(annot.Id);
        if (val.corrId) {
          await server.deleteAnnotation(val.corrId);
        }
        await server.reloadDoc();
        await server.hideCompleting();
        return
      }
    }

    if (_.isArray(annot?.CustomData?.color)) {
      annot.FillColor = new instance.Annotations.Color(...annot.CustomData.color);
    }

    // templates should be locked if user is a signer
    if (annot.Subject && annot.Subject.includes('Template') && !isAdmin) {
      log('locking annot');
      annot.Locked = true;
    }

    /*
      If the annotation was a template, was not hidden (i.e.: not executed yet), and was set as
      required, draw the red required arrow.
    */

    /*
      There's an issue in which sometimes this function would be triggered while the annotation
      was selected, which led to the arrow being rendered in unwanted moments. To avoid that we
      check also check if the annotation was selected to decide if drawing the arrow or not.
    */
    const isSelected = annotManager.isAnnotationSelected(annot);

    if (isIframe && annot.Subject.includes('Template') && !annot.Hidden && !isSelected) {
      toggleRequiredArrow(instance, annot, annot.CustomData?.flags?.required);
    }

    annot.authorId = annot.Author = annot.Author || val.authorId;

    // Resize text annots after typing
    // TODO: FIX THIS
    // if (annot instanceof instance.Annotations.FreeTextAnnotation && !annot.Subject.includes('Template')) {
    //   log('resizing annot because it is a free text and not a template', annot);
    //   const doc = docViewer.getDocument();
    //   const pageInfo = doc.getPageInfo(annot.PageNumber - 1);
    //   const pageMatrix = doc.getPageMatrix(annot.PageNumber - 1);

    //   annot.fitText(pageInfo, pageMatrix);
    //   annot.Width += 10;
    // }

    // annot.setContents('OMG TIPPY TP SHAPE');
    if (annot instanceof instance.Annotations.FreeTextAnnotation && _.isEmpty(annot.getContents())) {
      if (currentUser !== annot.authorId) {
        annot.setContents(annot?.CustomData?.initialText || 'Insert text here');
      }
    }
    await annotManager.redrawAnnotation(annot);
  });

  const annot = annotManager.getAnnotationById(val.corrId || val.id);

  // hide corresponding annotation if corrId specified.
  if (val.corrId || val.hidden) {
    if (annot) {
      if (!annot.Hidden) {
        await annotManager.hideAnnotation(annot);
        annot.Hidden = true;
        await annotManager.redrawAnnotation(annot);
      }

      // Remove required arrows when hiding template annotations.
      cleanRequiredArrow(instance, annot.Id, annot.PageNumber);

      /*
        When a template is hidden we want to know if it belonged to a group or not. If it did we
        want to toggle the arrow for the other members of the group. This is necessary otherwise
        when executing tags the other ones in the group would lose their arrow.
      */
      const annotGroup = annotManager.getGroupAnnotations(annot);

      if (_.size(annotGroup) > 1) {
        _.forEach(annotGroup, (annot) => {
          if (annot.Subject.includes('Template') && !annot.Hidden) {
            toggleRequiredArrow(instance, annot, annot.CustomData?.flags?.required);
          }
        });
      }
    }
  } else {
    /*
      In a specific scenario, template tags may inadvertently remain hidden when they shouldn't be.

      This happens, for instance, when someone deletes executed annots while the others have not yet
      loaded the document. The issue stems from the intricacies of importing data from Firebase,
      leading to potential instances where templates are not displayed when they should.

      In this particular edge case, a mismatch occurs between the `hidden` value in FB and the
      `Hidden` property of the annotation. Given that the data from FB is likely more up-to-date,
      we update the annotation's `Hidden` property to align with value from FB.

      This issue was addressed in the notary side by locking the document for participants when the
      notary hasn't loaded the document yet. Unfortunately, the same approach cannot be applied to
      the participant side, leading to the necessity of this "workaround".
    */
    if (
      annot
      && annot.Subject.includes('Template')
      && !_.isUndefined(val.hidden)
      && annot.Hidden !== val.hidden
      && selectedDoc === val.docId
    ) {
      if (val.hidden) {
        await annotManager.hideAnnotation(annot);
      } else {
        await annotManager.showAnnotation(annot);
      }

      annot.Hidden = val.hidden;
      await annotManager.redrawAnnotation(annot);

      toggleRequiredArrow(instance, annot, annot.CustomData?.flags?.required);
    }
  }
};

/**
 * deletes annot based on value pushed from firebase
 * if `corrId` specified, then it will find and show annotation with that id if it is hidden
 */
export const delFbaseVal = ({ annotManager }) => async ({ key, val }) => {
  await annotManager.importAnnotCommand(`<delete><id>${key}</id></delete>`);

  if (val.corrId) {
    const annot = annotManager.getAnnotationById(val.corrId);

    if (annot && annot.Hidden) {
      annotManager.showAnnotation(annot);
    }
  }
};

/**
 * deletes widget annot based on value pushed from firebase if widget exists
 */
export const delWidgetFbaseVal = ({ annotManager }) => async ({ key }) => {
  const widget = annotManager.getWidgetById(key);

  if (widget) {
    annotManager.deleteAnnotation(widget, true, true, false);
  }
};

/**
 * Imports widget annot pushed from firebase into webviewer if it doesn't already exist
 */
export const importWidgetFbaseVal = (instance) => async ({ val, key }) => {
  const { selectedDoc, annotManager } = instance;

  if (selectedDoc !== val.docId) {
    log('selectedDoc !== widgetVal.docId', { selectedDoc, docId: val.docId });

    return;
  }

  const widget = annotManager.getWidgetById(key);

  if (!widget) {
    log(`importing widget: ${val.id}`);
    const [annotation] = await annotManager.importAnnotations(val.xfdf);

    if (annotation) {
      await annotation.resourcesLoaded();
      // Set a custom field authorId to be used in client-side permission check
      annotation.authorId = annotation.Author = annotation.Author || val.authorId;
      annotation.CustomData = { ...annotation.CustomData, ..._.omit(val, ['xfdf']) };


      if (val.fieldName && val.fieldValue && annotation.getField) {
        const field = annotation.getField();

        if (field && field.value !== val) {
          field.setValue(val.fieldValue);
        }
      }

      if (val.hidden === true) {
        instance.annotManager.hideAnnotations([annotation]);
      } else if (val.hidden === false) {
        instance.annotManager.showAnnotations([annotation]);
      }

      annotManager.redrawAnnotation(annotation);
      annotManager.trigger('updateAnnotationPermission', [annotation]);
    }
  } else {
    log(`widget found skipping: ${val.id}`);
    const widget = annotManager.getWidgetById(key);

    if (val.hidden === true) {
      instance.annotManager.hideAnnotations([widget]);
    } else if (val.hidden === false) {
      instance.annotManager.showAnnotations([widget]);
    }
  }
};

/**
 * Checks if field exists and if it does will set its value
 */
export const importField = ({ annotManager }) => async ({ val }) => {
  const mgr = annotManager.getFieldManager();
  const field = mgr.getField(val.name);

  if (field) {
    field.setValue(val.value);
  }
};

/**
 * Set the number of blank pages
 */
export const setBlankPages = ({ selectedDoc, docViewer }) => async ({ val, key }) => {
  if (selectedDoc !== key) {
    log('setBlankPages: selectedDoc !== val.docId', {});

    return false;
  }

  return docViewer.trigger('setBlankPages', [val]);
};

export const lockWebviewer = (instance) => async ({ val }) => {
  instance.docViewer.trigger('setLockStatus', [val]);

  if (!instance.annotManager.getIsAdminUser()) {
    return instance.docViewer.lockWebviewer(val);
  }
};

export const setSigners = (inst) => async ({ val }) => inst.annotManager.trigger('setSigners', _.values(val));

export const setCurrentUser = (inst) => async ({ val }) => inst.annotManager.setCurrentUser(val);

export const setSelectedSigner = (inst, appState) => ({ val }) => {
  appState.setSelectedSigner(val);

  if (inst.annotManager.getIsAdminUser()) {
    inst.toggleTools(false);

    return;
  }

  // if signers can be located in different places
  if (appState.signerLocation === 'remote') {
    // if (val === '-1') {
    inst.annotManager.setCurrentUser('-1');
    inst.toggleTools(true);
    appState.setCurrentUser('-1');
    // }

    // if (val === '-1' || (inst.getSignerById(val) && appState.runId !== inst.getSignerById(val).runId)) {
    //   inst.annotManager.setCurrentUser('-1');
    //   inst.toggleTools(true);
    //   appState.setCurrentUser('-1');
    // } else if ((inst.getSignerById(val) && appState.runId === inst.getSignerById(val).runId)) {
    //   inst.annotManager.setCurrentUser(val);
    //   inst.toggleTools(false);
    //   appState.setCurrentUser(val);
    // }
  } else {
    inst.annotManager.setCurrentUser(val);
    appState.setCurrentUser(val);
    inst.toggleTools(val === '-1');
  }

  inst.annotManager.trigger('setSelectedSigner', val);
};

export const setModifiedXfdf = R.curry(async (server, inst) => {
  if (!inst.selectedDoc || inst.selectedDoc === '-1') {
    return false;
  }

  const annotList = _.filter(inst.annotManager.getAnnotationsList(), (annot) => !annot.Subject || !annot.Subject.includes('Template'));
  const xfdf = await inst.annotManager.exportAnnotations({
    annotList,
    widgets: true,
    fields: true,
  });

  return server.saveModifiedXfdf(inst.selectedDoc, xfdf);
});

export const nsCompleting = (inst) => ({ val }) => {
  if (val) {
    inst.hideMessage();// Hide any other currently open messages
    if (_.isString(val)) {
      inst.showMessage(val);
    } else {
      inst.showMessage('Completing...');
    }
  } else {
    inst.hideMessage();
  }
};

export const setCorrAnnot = (server, type = 'add') => (val) => {
  if (val.corrId) {
    server.updateAnnotation(val.corrId, { hidden: type === 'add', });
  }

  if (val.widgetId) {
    if (type === 'add') {
      server.deleteWidget(val.widgetId);
    }
  }
};
