/* eslint-disable no-param-reassign */
import { WebViewerInstance, Annotations } from '@pdftron/webviewer';
import { createFreeTextAnnot, ICreateAnnotationOptions } from '../createAnnotation';

export const createNotaryVerbiage = async (
  instance: WebViewerInstance,
  notaryVerbiage: string,
  annot: Annotations.FreeTextAnnotation,
  options: ICreateAnnotationOptions | Record<string, any> = {}
) => {

  // NOTE: if name doesn't exist just hide template
  if (notaryVerbiage) {
    return createFreeTextAnnot(instance, notaryVerbiage, annot, options);
  }

  console.warn('notaryVerbiage.createAnnotation: notaryVerbiage was empty')
  return null;
};

