import createTemplateTool from './createTemplateTool';
import {
  firstNameTemplateToolIcon,
  middleNameTemplateToolIcon,
  lastNameTemplateToolIcon,
  fullNameTemplateToolIcon,
} from '../../constants/icons';
import { nameToolsGroup } from '../../constants/toolGroups';
import { hotKeys } from '../../constants/hotKeys';


const createFirstNameTemplateTool = createTemplateTool('FirstName', firstNameTemplateToolIcon, null, nameToolsGroup);
const createMiddleNameTemplateTool = createTemplateTool('MiddleName', middleNameTemplateToolIcon, null, nameToolsGroup);
const createLastNameTemplateTool = createTemplateTool('LastName', lastNameTemplateToolIcon, null, nameToolsGroup);
const createFullNameTemplateTool = createTemplateTool('FullName', fullNameTemplateToolIcon, hotKeys.N, nameToolsGroup);

export {
  createFirstNameTemplateTool,
  createMiddleNameTemplateTool,
  createLastNameTemplateTool,
  createFullNameTemplateTool,
};
