import * as R from 'ramda';
import { nsUserProps, userProps } from './constants';

export const filterSignerProps = R.pipe(
  R.pick(nsUserProps),
  R.converge(R.mergeDeepRight, [
    R.applySpec({
      user: R.pipe(R.prop('user'), R.defaultTo({}), R.pick(userProps)),
    }),
    R.omit(['user']),
  ])
);

export const parseSignersArr = R.pipe(
  R.map(filterSignerProps),
  R.indexBy(R.prop('id'))
);
