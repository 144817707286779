import { ButtonGroup, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

const DocNotarizationTypesWrapper = styled.ul`
  padding: 0 15px;
  border-radius: 5px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);

  li {
    padding-left: 1em;
  }

  li::before {
    display: none;
  }

  li > a {
    margin-bottom: 5px;
    align-self: center;
    word-break: break-all;
  }

  div.btn-group {
    height: 2em;
    align-self: center;
  }
`;


const SelectNotarySessionType = ({ title, selected, sessionTypes, onChange }) => (
  <li className='row'>
    <div className='col-md-6 col-lg-4'>{title}</div>
    <ButtonGroup
      aria-label='Select Notarization Type'
      size='sm'
      className='col-md-6 col-lg-8'
    >
      {
        _.map(sessionTypes, ({ key, display }) => (
          <Button
            key={key}
            className={`btn-${selected === key ? 'primary' : 'default'}`}
            onClick={onChange(key)}
          >
            {display}
          </Button>
        ))
      }
    </ButtonGroup>
  </li>
);


export function SelectNotarizationTypeMultiDoc({
  onAllDocNsTypesSelected,
  onSessionTypeSelected,
  onChangeNotarizationType,
  onChangeDocNotarizationType,
  docs,
  sessionTypes,
  listTypes,
  notarizationType,
}) {
  const [nsTypes, setSelectedNsTypes] = useState(_.mapValues(docs, ({ notarizationType }) => notarizationType));
  const [sessionType, setSessionType] = useState(notarizationType);

  // when notarization types for all docs selected
  useEffect(() => {
    onAllDocNsTypesSelected(_.keys(docs).length === _.keys(nsTypes).length);
  }, [nsTypes, docs, onAllDocNsTypesSelected]);

  // when session types is selected and not undertermined
  useEffect(() => {
    onSessionTypeSelected(!_.isNil(sessionType) || !_.isEqual(sessionType, 'undetermined'));
  }, [sessionType, docs, onSessionTypeSelected]);

  return (
    <div className='white-block'>
      <h6>Select Notarization Types</h6>

      <DocNotarizationTypesWrapper className='ul-doc-list'>
        <SelectNotarySessionType
          selected={sessionType}
          title='Type of Session'
          sessionTypes={sessionTypes}
          onChange={(key) => async () => {
            setSessionType(key);
            await onChangeNotarizationType(key);
            // await changeNotarizationType(router.query.nsId, key);
          }}
        />

        {
          _.map(docs, (doc) => (
            <SelectNotarySessionType
              key={doc.id}
              selected={nsTypes[doc.id]}
              title={doc.title}
              sessionTypes={listTypes}
              onChange={(key) => async () => {
                setSelectedNsTypes(() => ({ ...nsTypes, [doc.id]: key }));
                await onChangeDocNotarizationType(doc.id, key);
              }}
            />
          ))
        }
      </DocNotarizationTypesWrapper>
    </div>
  );
}

SelectNotarizationTypeMultiDoc.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  docs: PropTypes.object.isRequired,
  onAllDocNsTypesSelected: PropTypes.func.isRequired,
  onSessionTypeSelected: PropTypes.func.isRequired,
  onChangeNotarizationType: PropTypes.func.isRequired,
  onChangeDocNotarizationType: PropTypes.func.isRequired,

  notarizationType: PropTypes.string,
  listTypes: PropTypes.arrayOf(PropTypes.object),
  sessionTypes: PropTypes.arrayOf(PropTypes.object),
};

SelectNotarizationTypeMultiDoc.defaultProps = {
  notarizationType: 'undetermined',
  listTypes: [
    { key: 'undetermined', display: 'NONE' },
    { key: 'oath', display: 'OATH' },
    { key: 'acknowledgement', display: 'ACKNOWLEDGEMENT' },
    { key: 'esign', display: 'ESIGN' },
  ],
  sessionTypes: [
    { key: 'ron', display: 'RON' },
    { key: 'rin', display: 'RIN' },
  ],
};

export default SelectNotarizationTypeMultiDoc;
