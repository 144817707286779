export const nsUserProps = [
  'id',
  'connectedAt',
  'firstName',
  'middleName',
  'lastName',
  'userId',
  'runId',
  'personallyKnown',
  'status',
  'kbaRequired',
  'kbaPassed',
  'documentVerified',
  'user',
  'type',
  'notary',
  'joined',
  'ready',
  'connected',
  'credAnalysisRequired',
];

export const userProps = [
  'id',
  'firstName',
  'middleName',
  'lastName',
];
