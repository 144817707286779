import { useContext } from 'react';
import { ServerCtx } from './context';
import { initServer } from './server'

// hook
type Server = Awaited<ReturnType<typeof initServer>>


export const useServer = () => {
  const server: Server = useContext(ServerCtx);

  if (!server) {
    throw new Error('useServer must be called within <ServerProvider/> context');
  }

  return server;
};
