export default {
  toolNames: [
    'ShowSigner',
    'Divider',
    'CheckboxTool',
    'RadioButtonTool',

  ],
  contextMenuItems: [
    'panToolButton',
  ],
};
