//RGBA [Red, Green, Blue, Alpha] Values used for Signer Tags (prior to executing)
export const participantColors = [
  [153, 215, 114, 0.5],
  [255, 10, 102, 0.5],
  [38, 79, 120, 0.5],
  [239, 106, 70, 0.5],
  [238, 145, 142, 0.5],
  [255, 87, 126, 0.5],
  [238, 224, 86, 0.5],
  [193, 151, 109, 0.5],
  [157, 157, 157, 0.5],
  [160, 146, 236, 0.5],
];
export const notaryColor = [91, 215, 227, 0.5];
export const orangeTagColor = [255, 141, 0, 0.5]
export const rgbaBlack = [0, 0, 0, 1]; 
export const eNotePalette = ['#000000'];
/*
  Default palettes separated into two arrays since for the 2022.11 release it was requested that
  "text" based signatures use the "old" light blue color, while signatures/initials must use the
  "new" dark blue color.

  Refer to ticket V2-4883 for context.
*/
export const defaultSignaturePalette = ['#0000FF', '#000000']; // #0000FF === dark blue.
export const defaultTextPalette = ['#4B92DB', '#000000']; // #4B92DB === light blue.
export const blackInkOnlyText = {
  value: '#000',
  label: 'Black Ink'
}
export const defaultFontSize = '11pt';