import * as R from 'ramda';
import _ from 'lodash';
import { showToastMessage, checkIfAllSignerRequiredTags } from '@enotarylog/shared';
import registerTool from '../initializers/registerTool';
import { injectTool } from '../initializers/injectors';
import registerHeaderGroup from '../initializers/registerHeaderGroup';
import createStampAnnot from '../annots/stamp';
import { stampToolIcon } from '../constants/icons';
import getToggleButton from '../helpers/getToggleButton';
import getHasNotarySealedAndSigned from '../helpers/getHasNotarySealedAndSigned';

const showErrorToast = (message) => {
  showToastMessage(window, {
    type: "error",
    message,
  });
}

const canExecute = (instance) => {
  const hasDocumentRequiredParticipantTags = instance.getEnableValidations();

  if (hasDocumentRequiredParticipantTags && getHasNotarySealedAndSigned(instance)) {
    showErrorToast("You cannot alter this document once the Notary has placed their Signature and Stamp. Please alert your Notary if you need to make any changes.");
    return false;
  };

  return true;
};

export const createStampAnnotation = ({
  name,
  imageUrl,
  buttonImage,
  tooltip,
  width,
  button,
}) => R.pipeP(
  // define annot class
  createStampAnnot({
    name,
    imageUrl,
    buttonImage,
    width,
  }),

  registerTool({
    type: 'toolButton',
    toolName: `${name}Tool`,
    buttonImage,
    dataElement: name,
    tooltip,
    button,
    // hidden: ['tablet', 'mobile'],
  })
);


const createStampAnnotTool = R.pipeP(
  createStampAnnotation({
    name: 'NotaryStamp',
    imageUrl: (args) => (!_.isEmpty(args.config.images.stamp)) ? args.config.images.stamp : '/static/img/notary_stamp_remove_bg.png',
    buttonImage: '/static/img/notary_stamp_remove_bg.png',
    tooltip: 'Notary Stamp',
    width: 150,
  }),
  createStampAnnotation({
    name: 'NotarySeal',
    imageUrl: (args) => (!_.isEmpty(args.config.images.seal)) ? args.config.images.seal : '/static/img/notary_seal.png',
    buttonImage: '/static/img/notary_seal.png',
    tooltip: 'Notary Seal',
    width: 150,
  }),
  createStampAnnotation({
    name: 'Checkbox',
    imageUrl: () => '/lib/assets/checkBoxOn.png',
    buttonImage: '/lib/assets/checkBoxOn.png',
    tooltip: 'Checkbox',
    width: 14,
    button: (instance) => ({
      type: 'customElement',
      render: getToggleButton(instance, {
        name: 'CheckboxTool',
        buttonImage: '/lib/assets/checkBoxOn.png',
        canExecute,
      }),
      title: 'Checkbox',
      dataElement: 'ChecboxButton',
    }),
  }),
  createStampAnnotation({
    name: 'RadioButton',
    imageUrl: () => '/lib/assets/radioButtonOn.png',
    buttonImage: '/lib/assets/radioButtonOn.png',
    tooltip: 'RadioButton',
    width: 14,
    button: (instance) => ({
      type: 'customElement',
      render: getToggleButton(instance, {
        name: 'RadioButtonTool',
        buttonImage: '/lib/assets/radioButtonOn.png',
        canExecute,
      }),
      title: 'RadioButton',
      dataElement: 'RadioButton',
    }),
  }),
  injectTool('StampTools', {
    type: 'actionButton',
    img: stampToolIcon,
    title: 'Stamps',
    dataElement: 'stampToolGroupButton',
    onClick: ({ instance }) => () => {
      if (checkIfAllSignerRequiredTags(instance.annotManager.getAnnotationsList())){
        instance.setActiveHeaderGroup('stampToolsGroup');
      }
      else {
        showErrorToast("All required signer tags on current document must be completed before the notary can affix their stamp or signature");
      }
    }


  }),
  registerHeaderGroup({
    groupName: 'stampToolsGroup',
    toolNames: [
      'NotaryStampTool',
      'NotarySealTool',
    ],
  })
);

export default createStampAnnotTool;
