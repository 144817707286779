import { useLocation, useParams as _useParams } from "react-router-dom";
import { useMemo } from 'react';


export function useParams<TParams extends { [K in keyof TParams]?: string }>(): TParams {
  const location = useLocation();
  const params = _useParams();

  const allParams = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const keys: Record<string, string> = {};
    searchParams.forEach((val, key) => {
      keys[key] = val;
    });
    return {
      ...params,
      ...keys
    }
  }, [location.search, params]);


  return allParams as TParams;
}


export const withParams = <P extends Record<string, any>>(Component: React.ComponentType<P>): React.FunctionComponent<P & { params: Record<string, string> }> => {

  function WithParamsComponent(props: P) {
    const params = useParams();

    return (
      <Component
        {...props}
        params={params}
      />
    );
  }

  return WithParamsComponent;
}
