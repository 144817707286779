/* eslint-disable no-param-reassign */
import _ from 'lodash';

const registerTool = ({
  toolName,
  annotClassName,
  buttonImage,
  buttonName,
  tooltip,
  showColor,
  colorPalette,
  button,
}, opts = {}) => async ({ instance, config, tools, toolClasses, annotClasses, ...rest }) => {
  // register the tool
  const toolObject = new toolClasses[toolName](instance.docViewer);

  if (opts.clearOnDraw) {
    toolObject.on('annotationAdded', () => instance.setActiveHeaderGroup('default'));
  }

  if (opts.hotKey) {
    instance.hotkeys.on(opts.hotKey, () => {
      if (opts.toolGroup) {
        // NOTE: if editing free text don't switch header
        const freetextAnnots = instance.annotManager.getAnnotationsList().filter((annot) => annot instanceof instance.Annotations.FreeTextAnnotation);

        if (freetextAnnots) {
          const isEditingFreetext = freetextAnnots.some((annot) => annot.getEditor()?.hasFocus());

          if (isEditingFreetext) return;
        }


        instance.setActiveHeaderGroup(opts.toolGroup);
      }

      instance.setToolMode(toolName);
    });
  }


  await instance.registerTool({
    toolName,
    buttonImage,
    buttonName,
    tooltip,
    toolObject,
    showColor,
  }, annotClasses[annotClassName]);

  instance.Tools[toolName] = toolClasses[toolName];

  if (colorPalette) {
    instance.setColorPalette({
      toolNames: [toolName],
      colors: colorPalette,
    });
  }

  return {
    ...rest,
    instance,
    toolClasses,
    annotClasses,
    config,
    tools: {
      ...tools,
      [toolName]: (button) ? button(instance) : {
        type: 'toolButton',
        toolName,
        dataElement: _.lowerFirst(toolName),
        // hidden: ['tablet', 'mobile'],
      },
    },
  };
};


export default registerTool;
