import * as R from 'ramda';
import _ from 'lodash';
import { signHere, initialHere } from '@enotarylog/tag-utils';
import registerTool from '../initializers/registerTool';

import applyFormFieldCreate from '../actions/applyFormFieldCreate';
import initRectAnnot from '../initializers/initRectAnnot';
import registerAnnotationType from '../initializers/registerAnnotationType';
import registerHeaderGroup from '../initializers/registerHeaderGroup';
import { injectTool } from '../initializers/injectors';
import defineToolClass from '../initializers/defineToolClass';
import createTextField from '../actions/createTextField';

import {
  sigToolImg,
  initialsToolImg,
  textToolImg,
  formFieldToolsIcon,
  applySigFieldToolImg,
  checkboxToolImg,
  initialsTemplateToolIcon,
  sigTemplateToolIcon,
} from '../constants/icons';
import createStampTemplateTag from '../annots/stampTag';


const createFormFieldTool = (name, buttonImage) => R.pipeP(
  initRectAnnot(name, {
    type: _.toUpper(name),
    label: 'Signature',
  }),
  defineToolClass({
    className: `${_.upperFirst(name)}FieldTool`,
    annotClassName: `${name}RectAnnot`,
    baseClassName: 'GenericAnnotationCreateTool',
    onAnnotationAdded: createTextField({
      name,
      annotClassName: `${name}FreeTextAnnot`,
    }),
  }),
  registerAnnotationType({
    elementName: 'formField',
    annotClassName: `${_.upperFirst(name)}FreeTextAnnot`,
  }),
  registerTool({
    annotClassName: `${_.upperFirst(name)}FreeTextAnnot`,
    toolName: `${_.upperFirst(name)}FieldTool`,
    buttonImage,
    buttonName: `${_.upperFirst(name)}FieldTool`,
    tooltip: `${_.upperFirst(name)} Field Tool`,
  })
);


const createApplySigFieldTool = R.pipeP(
  defineToolClass({
    className: 'ApplySigFieldTool',
    baseClassName: 'Tool',
    switchIn: applyFormFieldCreate(),
  }),
  registerTool({
    toolName: 'ApplySigFieldTool',
    buttonImage: applySigFieldToolImg,
    buttonName: 'applySigFieldFieldTool',
    tooltip: 'Apply Field Tool',
  })
);


export const defineAnnotTools = R.pipeP(
  createStampTemplateTag({
    name: 'SignHereTagTemplate',
    imageUrl: '/lib/assets/signHere.svg',
    svgPath: signHere,
    buttonImage: sigTemplateToolIcon,
    tooltip: 'Signature Tag',
    width: 150,
    toolGroup: 'formFieldToolsGroup',
  }),
  createStampTemplateTag({
    name: 'InitialHereTagTemplate',
    imageUrl: '/lib/assets/initialHere.svg',
    svgPath: initialHere,
    buttonImage: initialsTemplateToolIcon,
    tooltip: 'Initial Tag',
    width: 150,
    toolGroup: 'formFieldToolsGroup',
  }),
  createFormFieldTool('Signature', sigToolImg),
  createFormFieldTool('Initials', initialsToolImg),
  createFormFieldTool('Text', textToolImg),
  createFormFieldTool('Check', checkboxToolImg),
  createApplySigFieldTool
);


const registerFormFieldTools = R.pipeP(
  defineAnnotTools,
  injectTool('FormFieldTools', {
    type: 'actionButton',
    img: formFieldToolsIcon,
    title: 'Form Field Tools',
    dataElement: 'formFieldTools',
    toolGroup: 'formFieldToolsGroup',
    onClick: ({ instance }) => () => instance.setActiveHeaderGroup('formFieldToolsGroup'),
  }),
  registerHeaderGroup({
    groupName: 'formFieldToolsGroup',
    toolNames: [
      // 'SignatureFieldTool',
      // 'InitialsFieldTool',
      'SignHereTagTemplateTool',
      'InitialHereTagTemplateTool',
      // 'CheckFieldTool',
      // 'TextFieldTool',
      // 'ApplySigFieldTool',
    ],
  })
);

export default registerFormFieldTools;
