import * as R from 'ramda';

import registerAssignSigner from './assignSigner';
import registerSetDateFormat from './setDateFormat';
import registerSetYearFormat from './setYearFormat';
import registerSetStateFormat from './setStateFormat';
import registerSetAddressFormat from './setAddressFormat';
import registerSetRequired from './setRequired';

const registerPopups = R.pipeP(
  registerAssignSigner,
  registerSetDateFormat,
  registerSetYearFormat,
  registerSetRequired,
  registerSetStateFormat,
  registerSetAddressFormat

);

export default registerPopups;
