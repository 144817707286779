import React, { useState } from 'react';
import _ from 'lodash';
import Promise from 'bluebird';

import { Dropdown, Spinner } from 'react-bootstrap';
import { useMediaQuery, useTheme } from '@material-ui/core';

const DocSelectorWrapper = ({ className, children }) => (
  <div
    className={className}
    style={{ boxShadow: 'none !important' }}
  >
    {children}
  </div>
);


export const DocSelector = React.memo(({ docs, selectedDoc, disabled = false, processedDocs = [], onChange, status = false }) => {
  const selected = docs?.[selectedDoc]?.title;
  const docsOrdered = _.sortBy(Object.values(docs), (f) => f.order);
  const theme = useTheme();

  const [isDisabled, setIsDisabled] = useState();
  const xlargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <DocSelectorWrapper className='col-xs-12 text-center doc-selector'>
      <Dropdown
        onSelect={async (newDocId) => {
          if (selectedDoc !== newDocId) {
            setIsDisabled(true);
            Promise.delay(1500)
              .then(() => setIsDisabled(false))
          }

          return onChange(newDocId);
        }}
      >
        <Dropdown.Toggle
          variant='blank'
          id='doc-select'
        >
          {(status === true) && selected?.length > 25 && !xlargeScreen ? _.truncate(selected, {
            length: 25,
          }) : (status === true) && selected?.length > 60 && xlargeScreen ? _.truncate(selected, {
            length: 60,
          }) : selected || 'Loading Docs...'}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {
            _.map(docsOrdered, ({ title, id }) => (
              <Dropdown.Item
                key={id}
                eventKey={id}
                disabled={isDisabled || processedDocs.indexOf(id) === -1 || selectedDoc === id || disabled}
                value={id}
              >
                {title}
                {
                  (id !== selectedDoc && processedDocs.indexOf(id) === -1) && (
                    <Spinner
                      animation='border'
                      style={{ marginLeft: '1rem' }}
                      size='sm'
                    />
                  )
                }

              </Dropdown.Item>
            ))
          }
        </Dropdown.Menu>
      </Dropdown>
    </DocSelectorWrapper>
  );
});

DocSelector.defaultProps = {
  docs: {},
};

export default DocSelector;
