import _ from 'lodash';

import { backIcon } from '../constants/icons';

const registerHeaderGroup = ({
  groupName,
  toolNames,
}) => async ({ instance, tools, ...rest }) => {
  const neededTools = _.map(toolNames, (toolName) => tools[toolName]);

  instance.setHeaderItems((obj) => {
    // eslint-disable-next-line no-param-reassign
    obj.headers[groupName] = [
      { type: 'spacer' },
      { type: 'divider' },
      ...neededTools,
      { type: 'divider' },
      {
        type: 'actionButton',
        img: backIcon,
        title: 'Back',
        dataElement: 'goBack',
        onClick: async () => {
          await instance.setToolMode('AnnotationEdit');
          await instance.setActiveHeaderGroup('default');
        },

      },
    ];

    return obj;
  });

  return {
    ...rest,
    tools,
    instance,
  };
};

export default registerHeaderGroup;
