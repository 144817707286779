import React, { Component } from 'react';
import _ from 'lodash';
import * as R from 'ramda';
import {
  setStateFormat,
} from '../setters';
import annotNameIncludes from '../../helpers/annotNameIncludes';


const getTemplateAnnots = R.filter(
  (annot) => annotNameIncludes(annot, 'StateTemplate')
);


export default class SetStateFormat extends Component {
  constructor(...args) {
    super(...args);
    this.state = { stateFormat:  { value: 'full', display: 'Full' }};
    this.formatOptions = [
      { value: 'full', display: 'Full' },
      { value: 'Abbr', display: 'Abbreviation' },
     
      
    ];
  }

  componentDidMount = async () => {
    const selectedAnnots = this.props.annotManager.getSelectedAnnotations();

    const annots = _.filter(selectedAnnots, (a) => annotNameIncludes(a, 'Template'));

    if (annots) {
      const stateFormat = _.chain(annots)
        .head()
        .get('CustomData.flags.stateFormat')
        .value();

      await this.setState({ stateFormat });
    }
  }

  render() {
    const selectedAnnots = this.props.annotManager.getSelectedAnnotations();
    const annots = getTemplateAnnots(selectedAnnots);

    // TODO: set annot.Author and annot.CustomData.type
    if (annots.length === selectedAnnots.length) {
      return (
        <div style={{ paddingRight: 5 }}>
          <label htmlFor='signer'>State Format: </label>
          <select
            value={this.state.stateFormat}
            onChange={(ev) => {
              _.map(annots, (a) => {
                if (_.isNil(a.setStateFormat)) {
                  setStateFormat(a, ev.target.value, _.find(this.props.signers, { id: a.CustomData.signerId }), this.props.instance);
                } else {
                  a.setStateFormat(ev.target.value, _.find(this.props.signers, { id: a.CustomData.signerId }));
                }

                this.setState({ stateFormat: ev.target.value });
              });
            }}
          >
            {
              _.map(this.formatOptions, (format, index) => (
                <option
                  key={index}
                  value={format.value}
                >
                  {format.display}
                </option>
              ))
            }
          </select>
        </div>
      );
    }

    return null;
  }
}
