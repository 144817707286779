import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import * as animationData from './dog.json';
const LoadingOverlayComponent = styled.div`
  @font-face {
    font-family: 'Cera';
    src: url('/static/fonts/Cera-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cera';
    src: url('/static/fonts/Cera-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cera';
    src: url('/static/fonts/Cera-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cera';
    src: url('/static/fonts/Cera-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Cera';
    src: url('/static/fonts/Cera-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
  }

  & {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    font-family: Cera, sans-serif;
  }
  & {
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    overflow: hidden;
    z-index: 3000;
  }

  .MyLoader_overlay {
    z-index: 3000;
  }
`;
const LoadingMessage = styled.div`
  text-align: center;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 300ms ease;
  .loader {
    display: inline-block;
  }
  p {
    margin-bottom: 0;
  }
  &.hide-loader {
    opacity: 0;
  }
`;


const DogAnimation = styled.div`
  position: relative;
  width: 160px;
  height: 160px;
  margin-left: auto;
  margin-right: auto
`


const Img = styled.img`
position: absolute;
top: 50%;
left: 50%;
-webkit-transform: translate(-50%,-50%);
transform: translate(-50%,-50%);
z-index: 2;
display: block;
vertical-align: middle;
border-style: none;
`

class Waiting extends React.Component {
  messages = [
    'Please wait while we process your images',
    'The robots are working...',
    'The gears are turning...',
    'We are crunching the numbers...',
    'Almost done...',
    'We are almost there...',
  ]

  constructor(props) {
    super(props);
    this.state = {
      msgIndex: 0,
    };
  }


  componentDidMount() {
    const intervalId = setInterval(() => this.setState((prevState) => ({ msgIndex: (prevState.msgIndex + 1) % this.messages.length })), 25000);

    if (this.props.presence && this.props.presence.updateSessionStatus) {
      this.props.presence.updateSessionStatus('in-progress');
    }

    return this.setState({ intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }


  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <LoadingOverlayComponent>
        <LoadingMessage>
          <DogAnimation className='dog-animation'>
            <Img alt='Loading...' src='/static/img/loading.svg' />
            <Lottie
              options={defaultOptions}
              height={160}
              width={160}
            />
          </DogAnimation>
          {/* <div className='loader mt-1' /> */}
          <p className='mt-2'>We know this takes a few, but hey, at least you didn’t have to put on shoes! It's the little things...</p>
          {/* <p>{this.messages[this.state.msgIndex]}</p> */}
        </LoadingMessage>
      </LoadingOverlayComponent>
    );
  }
}

export default Waiting;
