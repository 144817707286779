import { fitText as getFontSizeToFit } from './fitText';
import { WebViewerInstance, Annotations } from '@pdftron/webviewer'


export interface ICreateAnnotationOptions {
  forceNoResize: boolean;
  color: Annotations.Color;
}

export const createFreeTextAnnot = async (
  instance: WebViewerInstance,
  date: string,
  annot: Annotations.FreeTextAnnotation,
  options: ICreateAnnotationOptions | Record<string, any> = {}
) => {
  const freeTextAnnot = new instance.Annotations.FreeTextAnnotation();
  const rotation = instance.docViewer.getCompleteRotation(annot.PageNumber || 1) * 90;
  const {R, G, B, A} = annot.TextColor


  freeTextAnnot.Rotation = rotation;
  freeTextAnnot.Author = instance.annotManager.getCurrentUser();
  freeTextAnnot.PageNumber = annot.PageNumber;
  freeTextAnnot.X = annot.X;
  freeTextAnnot.Y = annot.Y;
  freeTextAnnot.Height = annot.Height;
  freeTextAnnot.Width = annot.Width;
  freeTextAnnot.FontSize = annot.FontSize;
  freeTextAnnot.setPadding(new instance.Annotations.Rect(0, 0, 0, 0));
  freeTextAnnot.StrokeColor = new instance.Annotations.Color(255, 255, 255, 0);
  freeTextAnnot.StrokeThickness = 0;

  // FIXME: should default color be rgb(0. 0, 0, 1)?
  const color = (options.color) ? options.color as Annotations.Color : new instance.Annotations.Color(R, G, B, A);
  freeTextAnnot.TextColor = color;

  freeTextAnnot.CustomData.corrId = annot.Id;
  freeTextAnnot.CustomData.flags = annot.CustomData.flags;
  freeTextAnnot.CustomData.initialText = date;

  annot.Author = instance.annotManager.getCurrentUser();
  freeTextAnnot.Author = instance.annotManager.getCurrentUser();
  const newFontSize = getFontSizeToFit(instance, freeTextAnnot);

  freeTextAnnot.FontSize = `${newFontSize}pt`;
  freeTextAnnot.TextAlign = 'left';
  freeTextAnnot.Author = instance.annotManager.getCurrentUser();
  freeTextAnnot.setContents(date);
  console.log(date);

  if (annot.CustomData.flags.required) {
    freeTextAnnot.NoMove = true;
    freeTextAnnot.LockedContents = true;
  }


  if (options.forceNoResize) {
    freeTextAnnot.NoResize = options.forceNoResize;
  }

  // FIXME: is imported options supposed to be false?
  await instance.annotManager.addAnnotation(freeTextAnnot, false);
  // await instance.annotManager.deleteAnnotation(annot, true);
  instance.annotManager.hideAnnotation(annot);
  await instance.annotManager.drawAnnotationsFromList([freeTextAnnot, annot]);
  return freeTextAnnot;
};
