import * as R from 'ramda';
import { eSeal, signHere, initialHere, checkBox, freeFormText, radioButton } from '@enotarylog/tag-utils';
import registerTool from '../initializers/registerTool';
import registerHeaderGroup from '../initializers/registerHeaderGroup';
import { injectTool } from '../initializers/injectors';
import createStampAnnot from '../annots/stamp';
import { createFirstNameTemplateTool, createMiddleNameTemplateTool, createLastNameTemplateTool, createFullNameTemplateTool } from './templatingTools/nameTemplateTools';
import { createDateTemplateTool, createMonthTemplateTool, createDayTemplateTool, createYearTemplateTool } from './templatingTools/dateTemplateTools';

import {
  createNotaryVerbiageDocIdTemplateTool,
  createNotaryVerbiageCommissionIdTemplateTool,
  createNotaryVerbiageCommissionExpirationTemplateTool,
  createNotaryVerbiageNotarySessionIdTemplateTool,
  createNotaryVerbiageDisclaimerTemplateTool,
  createNotaryVerbiageTitleTemplateTool,
  createNotaryCountyTemplateTool,
  createNotaryStateTemplateTool,
} from './templatingTools/notaryVerbiageTemplateTool';
import {
  addressToolGroupIcon,
  dateToolGroupIcon,
  nameToolGroupIcon,
  sigTemplateToolIcon,
  initialsTemplateToolIcon,
  notaryToolGroupIcon,
  commissionStampIcon,
  freeTextToolIcon,
  checkboxToolImg, radioButtonToolIcon
} from '../constants/icons';
import { nameToolsGroup, notaryToolsGroup } from '../constants/toolGroups';
import { createStampTemplateTag } from '../annots/stampTag';
import { hotKeys } from '../constants/hotKeys';

export const createStampAnnotation = ({
  name,
  imageUrl,
  buttonImage,
  tooltip,
  width,
}) => R.pipeP(
  // define annot class
  createStampAnnot({
    name,
    imageUrl,
    buttonImage,
    width,
  }),

  registerTool({
    type: 'toolButton',
    toolName: `${name}Tool`,
    buttonImage,
    dataElement: name,
    tooltip,
    // hidden: ['tablet', 'mobile'],
  })
);


export const defineAnnotTools = R.pipeP(
  // createAddressTemplateTool,
  // createCityTemplateTool,
  // createStateTemplateTool,
  // createZipTemplateTool,
  createDateTemplateTool,
  createFirstNameTemplateTool,
  createMiddleNameTemplateTool,
  createLastNameTemplateTool,
  createFullNameTemplateTool,
  createMonthTemplateTool,
  createDayTemplateTool,
  createYearTemplateTool,
  createNotaryVerbiageDocIdTemplateTool,
  createNotaryVerbiageCommissionIdTemplateTool,
  createNotaryVerbiageNotarySessionIdTemplateTool,
  createNotaryVerbiageCommissionExpirationTemplateTool,
  createNotaryVerbiageDisclaimerTemplateTool,
  createNotaryVerbiageTitleTemplateTool,
  createNotaryCountyTemplateTool,
  createNotaryStateTemplateTool
);


const registerTemplateTools = R.pipeP(
  defineAnnotTools,
  injectTool('NotaryTools', {
    type: 'actionButton',
    img: notaryToolGroupIcon,
    title: 'Notary Tools',
    dataElement: 'notaryTools',
    onClick: ({ instance }) => () => instance.setActiveHeaderGroup('notaryToolsGroup'),
  }),
  injectTool('AddressFieldTools', {
    type: 'actionButton',
    img: addressToolGroupIcon,
    title: 'Address Field Tools',
    dataElement: 'addressFieldTools',
    onClick: ({ instance }) => () => instance.setActiveHeaderGroup('addressFieldTools'),
  }),
  injectTool('DateTools', {
    type: 'actionButton',
    img: dateToolGroupIcon,
    title: 'Date Tools',
    dataElement: 'dateTools',
    onClick: ({ instance }) => () => instance.setActiveHeaderGroup('dateToolsGroup'),
  }),
  injectTool('NameTools', {
    type: 'actionButton',
    img: nameToolGroupIcon,
    title: 'Participant Tools',
    dataElement: 'nameTools',
    onClick: ({ instance }) => () => instance.setActiveHeaderGroup('nameToolsGroup'),
  }),
  registerHeaderGroup({
    groupName: 'templateToolsGroup',
    toolNames: [
      'InitialsHereTemplateTool',
      'SignHereTemplateTool',
      'DateTools',
      'NameTools',
      'NotaryTools',
    ],
  }),

  // registerHeaderGroup({
  //   groupName: 'dateToolsGroup',
  //   toolNames: [
  //     'DateTemplateTool',
  //     'MonthTemplateTool',
  //     'DayTemplateTool',
  //     'YearTemplateTool',
  //   ],
  // }),

  createStampTemplateTag({
    name: 'CheckHereTagTemplate',
    imageUrl: '/lib/assets/checkBoxOff.svg',
    svgPath: checkBox,
    buttonImage: checkboxToolImg,
    tooltip: 'Checkbox Tag',
    width: 14,
    hotKey: null,
    toolGroup: nameToolsGroup,
  }),
  createStampTemplateTag({
    name: 'FreeFormTextTagTemplate',
    imageUrl: '/lib/assets/freeText.svg',
    svgPath: freeFormText,
    buttonImage: freeTextToolIcon,
    tooltip: 'Free-form text the signer can type into',
    width: 150,
    hotKey: null,
    toolGroup: nameToolsGroup,
  }),
  createStampTemplateTag({
    name: 'RadioButtonTagTemplate',
    imageUrl: '/lib/assets/radioButton.svg',
    svgPath: radioButton,
    buttonImage: radioButtonToolIcon,
    tooltip: 'Radio Button Tag',
    width: 14,
    hotKey: null,
    toolGroup: nameToolsGroup,
  }),
  createStampTemplateTag({
    name: 'SealTagTemplate',
    imageUrl: '/lib/assets/eSeal.svg',
    svgPath: eSeal,
    buttonImage: commissionStampIcon,
    tooltip: 'Commission Stamp Tag',
    width: 150,
    hotKey: hotKeys.O,
    toolGroup: notaryToolsGroup,
  }),
  createStampTemplateTag({
    name: 'SignHereTagTemplate',
    imageUrl: '/lib/assets/signHere.svg',
    svgPath: signHere,
    buttonImage: sigTemplateToolIcon,
    tooltip: 'Signature Tag',
    width: 150,
    hotKey: hotKeys.Y,
    toolGroup: nameToolsGroup,
  }),
  createStampTemplateTag({
    name: 'InitialHereTagTemplate',
    imageUrl: '/lib/assets/initialHere.svg',
    svgPath: initialHere,
    buttonImage: initialsTemplateToolIcon,
    tooltip: 'Initial Tag',
    width: 150,
    hotKey: '5',
    toolGroup: nameToolsGroup,
  }),
  registerHeaderGroup({
    groupName: nameToolsGroup,
    toolNames: [
      'SignHereTagTemplateTool',
      'InitialHereTagTemplateTool',
      'Divider',
      'DateTemplateTool',
      // 'FirstNameTemplateTool',
      // 'MiddleNameTemplateTool',
      // 'LastNameTemplateTool',
      'FullNameTemplateTool',
      // NOTE: figure out why you can't load this twice
      // 'AnnotationCreateFreeText',
      // NOTE: Hide these for now
      // 'Divider',
      // 'AddressTemplateTool',
      // 'CityTemplateTool',
      // 'ZipTemplateTool',
      'CheckHereTagTemplateTool',
      'FreeFormTextTagTemplateTool',
      'RadioButtonTagTemplateTool',
    ],
  }),
  registerHeaderGroup({
    groupName: notaryToolsGroup,
    toolNames: [
      'NotaryStateTemplateTool',
      'NotaryCountyTemplateTool',
      'Divider',
      'DateTemplateTool',
      'DayTemplateTool',
      'MonthTemplateTool',
      'YearTemplateTool',
      'Divider',
      'SealTagTemplateTool',
      'DisclaimerTemplateTool',
      'CommissionIdTemplateTool',
      'CommissionExpirationTemplateTool',
      'NotaryTitleTemplateTool',
      'Divider',
      'DocumentIdTemplateTool',
      'NotarySessionIdTemplateTool',

    ],
  })
);

export default registerTemplateTools;
