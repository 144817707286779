// Currently only supports hex colors, which were the only requirement in the HOC ticket. Feel free to add more formats
export function isAColor(color: string) {
  if (!color) return false;

  if (color.startsWith('#')) {
    return /^#[0-9a-fA-F]{3}([0-9a-fA-F]{3}([0-9a-fA-F]{2})?)?$/.test(color);
  }

  return false;
}
