/* eslint-disable no-param-reassign */
import { WebViewerInstance, Annotations } from '@pdftron/webviewer'
import { parseDate } from '../../helpers/parseDate';
import { parseYear } from '../../helpers/parseYear';
import { tagTypes } from '../../constants/tagTypes';
import { createFreeTextAnnot, ICreateAnnotationOptions } from '../createAnnotation';



export const createDateFreeText = async (
  instance: WebViewerInstance,
  date: string,
  annot: Annotations.FreeTextAnnotation,
  options: ICreateAnnotationOptions | Record<string, any> = {}
) => {
  if (date) {
    return createFreeTextAnnot(instance, date, annot, options);
  }

  console.warn('date.createAnnotation: date was empty')
  return null;
};


export const createDayAnnotation = createDateFreeText;
export const createFullDateAnnotation = createDateFreeText;
export const createMonthAnnotation = createDateFreeText;
export const createYearAnnotation = createDateFreeText;




type DateType = keyof (typeof tagTypes.dateTags);
type YearType = keyof (typeof tagTypes.yearTags)

export const handleCreateYear = (instance: WebViewerInstance, text: string, tag: Annotations.FreeTextAnnotation, yearType: YearType, yearFormat: string, options = {}) => {
  tagTypes.yearTags[yearType].createYearAnnotation(instance, parseYear(yearFormat), tag, options);
};

export const handleCreateDate = (instance: WebViewerInstance, text: string, tag: Annotations.FreeTextAnnotation, dateType: DateType, dateFormat: string, options = {}) => {
  tagTypes.dateTags[dateType].createDateAnnotation(instance, parseDate(text, dateFormat), tag, options);
};


export default createDateFreeText;