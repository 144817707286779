const handleMessage = async ({ action, payload }) => {
  const { nsId, nsUserId, image, type } = payload;

  if (action === 'signatureSaved' || action === 'initialsSaved') {

    await fetch(`/consumer/api/notarization/${nsId}/participants/${nsUserId}/signature`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        type,
        image
      })
    })
    .then(res => res.json());
  }

  if (action === 'signatureDeleted' || action === 'initialsDeleted') {
    await fetch(`/consumer/api/notarization/${nsId}/participants/${nsUserId}/${type}`, {
      method: 'DELETE',
      headers: { 'content-type': 'application/json' },
    })
    .then(res => res.json());
  }
};

export default handleMessage;
