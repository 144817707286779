const AuditActions = {
  USER_APPLIED_SIGNATURE: 'USER_APPLIED_SIGNATURE',
  USER_REMOVED_SIGNATURE: 'USER_REMOVED_SIGNATURE',
  USER_MODIFIED_SIGNATURE: 'USER_MODIFIED_SIGNATURE',
  USER_INTERACTED_WITH_SIGNATURE: 'USER_INTERACTED_WITH_SIGNATURE',

  USER_APPLIED_TEXT: 'USER_APPLIED_TEXT',
  USER_REMOVED_TEXT: 'USER_REMOVED_TEXT',
  USER_MODIFIED_TEXT: 'USER_MODIFIED_TEXT',
  USER_INTERACTED_WITH_TEXT: 'USER_INTERACTED_WITH_TEXT',

  USER_APPLIED_CHECKBOX: 'USER_APPLIED_CHECKBOX',
  USER_REMOVED_CHECKBOX: 'USER_REMOVED_CHECKBOX',
  USER_APPLIED_RADIOBUTTON: 'USER_APPLIED_RADIOBUTTON',
  USER_REMOVED_RADIOBUTTON: 'USER_REMOVED_RADIOBUTTON',

  NOTARY_APPLIED_SIGNATURE: 'NOTARY_APPLIED_SIGNATURE',
  NOTARY_REMOVED_SIGNATURE: 'NOTARY_REMOVED_SIGNATURE',
  NOTARY_MODIFIED_SIGNATURE: 'NOTARY_MODIFIED_SIGNATURE',
  NOTARY_INTERACTED_WITH_SIGNATURE: 'NOTARY_INTERACTED_WITH_SIGNATURE',

  NOTARY_APPLIED_CHECKBOX: 'NOTARY_APPLIED_CHECKBOX',
  NOTARY_REMOVED_CHECKBOX: 'NOTARY_REMOVED_CHECKBOX',
  NOTARY_APPLIED_RADIOBUTTON: 'NOTARY_APPLIED_RADIOBUTTON',
  NOTARY_REMOVED_RADIOBUTTON: 'NOTARY_REMOVED_RADIOBUTTON',

  NOTARY_APPLIED_TEXT: 'NOTARY_APPLIED_TEXT',
  NOTARY_REMOVED_TEXT: 'NOTARY_REMOVED_TEXT',
  NOTARY_MODIFIED_TEXT: 'NOTARY_MODIFIED_TEXT',
  NOTARY_INTERACTED_WITH_TEXT: 'NOTARY_INTERACTED_WITH_TEXT',

  NOTARY_ADDED_BLANK_PAGE: 'NOTARY_ADDED_BLANK_PAGE',
  NOTARY_REMOVED_BLANK_PAGE: 'NOTARY_REMOVED_BLANK_PAGE',

  NOTARY_APPLIED_COMMISSION_STAMP: 'NOTARY_APPLIED_COMMISSION_STAMP',
  NOTARY_REMOVED_COMMISSION_STAMP: 'NOTARY_REMOVED_COMMISSION_STAMP',
  NOTARY_MODIFIED_COMMISSION_STAMP: 'NOTARY_MODIFIED_COMMISSION_STAMP',
  NOTARY_INTERACTED_WITH_COMMISSION_STAMP: 'NOTARY_INTERACTED_WITH_COMMISSION_STAMP',

  NOTARY_PROMPTED_VA_ACCEPTANCE: 'NOTARY_PROMPTED_VA_ACCEPTANCE',
  NOTARY_CLOSED_VA_ACCEPTANCE: 'NOTARY_CLOSED_VA_ACCEPTANCE',

  NOTARY_APPLIED_CERTIFICATE: 'NOTARY_APPLIED_CERTIFICATE',
  NOTARY_REMOVED_CERTIFICATE: 'NOTARY_REMOVED_CERTIFICATE',
  NOTARY_MODIFIED_CERTIFICATE: 'NOTARY_MODIFIED_CERTIFICATE',
  NOTARY_INTERACTED_WITH_CERTIFICATE: 'NOTARY_INTERACTED_WITH_CERTIFICATE',

  NOTARY_APPLIED_WHITEOUT: 'NOTARY_APPLIED_WHITEOUT',
  NOTARY_REMOVED_WHITEOUT: 'NOTARY_REMOVED_WHITEOUT',
  NOTARY_MODIFIED_WHITEOUT: 'NOTARY_MODIFIED_WHITEOUT',
  NOTARY_INTERACTED_WITH_WHITEOUT: 'NOTARY_INTERACTED_WITH_WHITEOUT',

  NOTARY_RESET_DOCUMENT: 'NOTARY_RESET_DOCUMENT',
};

export default AuditActions;

