import React from 'react';
import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { initialize } from 'launchdarkly-js-client-sdk';

import App, { AppProps } from './App';
import { firebaseConfig } from './config';
import localforage from 'localforage';
import { FirebaseConfig } from '@enotarylog/shared';
import { createRoot } from 'react-dom/client';

import DocVerificationLoading from './pages/doc-verification-redirect-to/loading';
import DocVerificationRetry from './pages/doc-verification-redirect-to/retry';



const store = (window as any).store = localforage.createInstance({
  name: 'pdfstore'
});
store.clear();


const history = createBrowserHistory();

const searchParams = new URLSearchParams(window.location.search);
const isDemo = searchParams.get('isDemo');
const isMock = searchParams.get('mock');
const type = searchParams.get('type') as 'notary' | 'signer';




(async () => {
  const client = initialize(process.env.NX_LAUNCH_DARKLY_API_KEY, {
    key: 'anonymous'
  });
  await client.waitForInitialization();
    const { domains = [] } = client.variation('lucky-orange', {});

    const host = window.location.host;
    const found = domains.find((el) => host.includes(el));

    if (found) {
      const head = document.querySelector("head");
      const script = document.createElement("script");

      script.setAttribute("src", '/lucky-orange.js');
      head.appendChild(script);

    }

})();

// run legacy-notary room when button is clicked
// document.getElementById('start-button-legacy').addEventListener('click', async () => {
//   const requiredPath = '/enterprise/room/284e2454-3ca1-403e-ad58-1c435849c89d?nsId=061da00b-ddfd-4d76-a438-427cf4e07207'
//   const query = window.location.href.split('?')[1]

//   if (`${window.location.pathname}?${query}` !== requiredPath) {
//     (window.location as any) = `${requiredPath}&mock=true`;
//     return;
//   }
// })


// // run v2 notary room when button is clicked
// document.getElementById('start-button').addEventListener('click', async () => {
//   const requiredPath = '/enterprise/room/v2/284e2454-3ca1-403e-ad58-1c435849c89d?nsId=061da00b-ddfd-4d76-a438-427cf4e07207&mock=true'
//   const query = window.location.href.split('?')[1]

//   if (`${window.location.pathname}?${query}` !== requiredPath) {
//     (window.location as any) = `${requiredPath}&mock=true`;
//     return;
//   }
// })

// // run consumer notary room when button is clicked
// document.getElementById('start-button-consumer').addEventListener('click', async () => {
//   const requiredPath = '/notarization/rooms/284e2454-3ca1-403e-ad58-1c435849c89d?nsId=061da00b-ddfd-4d76-a438-427cf4e07207&organizationId=8d976a23-b865-4fcd-9165-ddc0aedaf614&mock=true'
//   const query = window.location.href.split('?')[1]

//   if (`${window.location.pathname}?${query}` !== requiredPath) {
//     (window.location as any) = `${requiredPath}&mock=true`;
//     return;
//   }
// })

class Main extends React.Component<AppProps, { config: FirebaseConfig }> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      config: null
    }
  }

  async componentDidMount(): Promise<void> {
    const {
      firebase
    } = await fetch('/api/app-url').then(resp => resp.json());

    await this.setState({
      config: firebase
    });
  }

  render(): React.ReactNode {
    const { token, type } = this.props;


    if (!this.state.config) {
      return (<></>)
    }

    return (
      <Router history={history}>
        <App
          config={this.state.config}
          token={token}
          type={type}
        />
      </Router>
    )
  }
}



// const handleRunMock = async () => {

//   if (searchParams.has('nsId') && searchParams.has('mock')) {
//     console.log('has mock')
//     if (window.location.pathname.includes('/enterprise/room/')) {
//       const worker = getWorker(true);
//       await worker.start();

//       ReactDOM.render((
//         <Main
//           token={'lorem'}
//           config={firebaseConfig}
//           type='notary'
//         />
//       ), document.getElementById('root'), () => {
//         (window as any).mounted = true;
//       });
//     }
//     if (window.location.pathname.includes('/notarization/rooms/')) {
//       const worker = getWorker(false);
//       await worker.start();

//       ReactDOM.render((
//         <Main
//           token={'lorem'}
//           config={firebaseConfig}
//           type='signer'
//         />
//       ), document.getElementById('root'), () => {
//         (window as any).mounted = true;
//       });

//     }
//   }

// };
// handleRunMock();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript


// when parent window passes down auth token, mount the top-level react component
window.addEventListener("message", async (event) => {


  if ((event.data.token) && (window as any).mounted !== true) {
    root.render((
      <Main
        token={event.data.token}
        config={event.data.config || firebaseConfig}
        type='notary'
      />
    ));

    (window as any).mounted = true;
  }

  if ((event.data.type === 'notarize') && (window as any).mounted !== true) {
    root.render((
      <Main
        token={event.data.token}
        config={event.data.config || firebaseConfig}
        type='notary'
      />
    ));

    (window as any).mounted = true;
  }
}, false);


const userType = (window.location.pathname || '').includes('/notarization/rooms') ? 'signer' : 'notary';


if (window.location.pathname.includes('/notarization/loading')) {
  root.render(
    <DocVerificationLoading/>,
  )
}
else if (window.location.pathname.includes('/notarization/retry')) {
  root.render(
    <DocVerificationRetry/>,
  )
}
else if (isDemo || window.location.pathname === '/' || window.location.pathname.includes('/verification-failure') || isMock || userType === 'signer') {
  const doRender = async () => {
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImVlMGIyNmJkLWY2MWMtNDBiZS1hZWQxLTA5YjllYzA4NGMwYyIsImVtYWlsIjoidGVzdG1hbjFAbWFpbGluYXRvci5jb20iLCJvcmdhbml6YXRpb25JZCI6IjhkOTc2YTIzLWI4NjUtNGZjZC05MTY1LWRkYzBhZWRhZjYxNCIsInJvbGVJZCI6IjNkMmM4NWJiLWY4MzktNGMzMi1iNzcwLTIwMTk2ZDA3M2Q2MCIsInVzZXJUeXBlIjoib3JnX2FkbWluIiwibm90YXJ5SWQiOiI4NTkwYmNlMS0xNmRjLTQ2Y2MtYmVjNS1hMmQ5NzllNDE1ODgiLCJleHAiOjE2MzA5NTExMzMsImlhdCI6MTYzMDQ0NjMzM30.hFMA3MLpIJjunKNaL7YiMMxR5gRBY7xHVbCEcQC_YF8';
    const defToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImVlMGIyNmJkLWY2MWMtNDBiZS1hZWQxLTA5YjllYzA4NGMwYyIsImVtYWlsIjoidGVzdG1hbjFAbWFpbGluYXRvci5jb20iLCJvcmdhbml6YXRpb25JZCI6IjhkOTc2YTIzLWI4NjUtNGZjZC05MTY1LWRkYzBhZWRhZjYxNCIsInJvbGVJZCI6IjNkMmM4NWJiLWY4MzktNGMzMi1iNzcwLTIwMTk2ZDA3M2Q2MCIsInVzZXJUeXBlIjoib3JnX2FkbWluIiwibm90YXJ5SWQiOiI4NTkwYmNlMS0xNmRjLTQ2Y2MtYmVjNS1hMmQ5NzllNDE1ODgiLCJpYXQiOjE2MzIwODIzNjksImV4cCI6MTYzMjE2ODc2OX0._OTbFbvRNeyVyhdcZLtRJz3nUd9MwvJUW2yLz8jY4UM`;
    const token = localStorage.getItem('token') || defToken;
    const _config = localStorage.getItem('firebaseConfig');

    let config: FirebaseConfig;
    if (_config) {
      config = JSON.parse(_config);
    }
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImIzNGI5MDJiLTkzODYtNDQyOC04OWUzLThjNjljMmJkOTE0YyIsImVtYWlsIjoib3J0aXouYXNobGV5cEBnbWFpbC5jb20iLCJvcmdhbml6YXRpb25JZCI6IjBjMWQ2Nzk1LTI1NDAtNGMxZS1iOTZhLWU4MGI1YzQyNTU4ZiIsInJvbGVJZCI6Ijk1MDYyMjI5LWIyZmEtNGRkYS1iYzVkLTQ3OGJjNTZkNmU1OCIsInVzZXJUeXBlIjoib3JnX2FkbWluIiwibm90YXJ5SWQiOiJhZjYyMTBmZC1mNzM0LTRhYjItYjQ3MS1jNWUyNmM1ODM4NjQiLCJleHAiOjE2MzA5NTA2ODEsImlhdCI6MTYzMDQ0NTg4MX0.EVIpTLoyOmas_CxsrI_Lt0F81wN9mEHYIEbTc21bqcY';

    root.render(
      <Main
        config={config || firebaseConfig}
        token={token}
        type={type || userType}
      />,
    );
  }
  doRender();
}





console.log({
  buildId: process.env.NX_BUILD_ID,
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
