import React, { Component } from 'react';
import _ from 'lodash';
import * as R from 'ramda';
import {
  setYearFormat,
} from '../setters';
import annotNameIncludes from '../../helpers/annotNameIncludes';


const getTemplateAnnots = R.filter(
    (annot) => annotNameIncludes(annot, 'YearTemplate')
  );
  
  
  export default class SetYearFormat extends Component {
    constructor(...args) {
      super(...args);
      this.state = { yearFormat: { value: 'yyyy', display: 'YYYY' } };
      this.formatOptions = [
        { value: 'yyyy', display: 'YYYY' },
        { value: 'yy', display: 'YY' },
      ];
    }
  
    componentDidMount = async () => {
      const selectedAnnots = this.props.annotManager.getSelectedAnnotations();
  
      const annots = _.filter(selectedAnnots, (a) => {
        return annotNameIncludes(a, 'Template');
      });
  
      if (annots) {
        const yearFormat = _.chain(annots)
          .head()
          .get('CustomData.flags.yearFormat')
          .value();
  
        await this.setState({ yearFormat });
      }
    }
  
    render() {
      const selectedAnnots = this.props.annotManager.getSelectedAnnotations();
      const annots = getTemplateAnnots(selectedAnnots);
  
      // TODO: set annot.Author and annot.CustomData.type
  
      if (annots.length === selectedAnnots.length) {
        return (
          <div style={{ paddingRight: 5 }}>
            <label htmlFor='signer'>Year Format: </label>
            <select
              value={this.state.yearFormat}
              onChange={(ev) => {
                _.map(annots, (a) => {
                  if (_.isNil(a.setYearFormat)) {
                    setYearFormat(a, ev.target.value, this.props.instance);
                  } else {
                    a.setYearFormat(ev.target.value);
                  }
  
                  this.setState({ yearFormat: ev.target.value });
                });
              }}
            >
              {
                _.map(this.formatOptions, (format, index) => {
                  return (
                    <option
                      key={index}
                      value={format.value}
                    >{format.display}
                    </option>
                  );
                })
              }
            </select>
          </div>
        );
      }
  
      return null;
    }
  }
  