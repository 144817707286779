export function signerListWithNotary(signers) {
  return [...signers, {
    id: `${signers.length + 1}`,
    type: 'notary',
    firstName: 'Notary FirstName',
    middleName: 'Notary MiddleName',
    lastName: 'Notary LastName',
    fullName: 'Notary',
  }];
}

export default signerListWithNotary;
